<template>
    <div class="LoaderWithOverlay">
        <Loader />
    </div>
</template>

<script>
export default {
    name: 'LoaderWithOverlay',
};
</script>

<style lang="scss">
    @import './styles.scss';
</style>
