// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetImpersonateQuery extends
    AbstractQueryResource<GetImpersonateResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($enrolleeId: String!) {
            impersonateEnrollee(enrolleeId: $enrolleeId)
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getImpersonate';
    }
}

export type GetImpersonateResultType = {
    impersonateEnrollee: string;
}

export type GetImpersonateParamsType = {
    enrolleeId: string;
}
