import dateManager from '@/utils/time';

const dateAccountedToWinterTime = (date): string => {
    // extract timezone based on DTS (summer/winter time)
    const timezone = dateManager.getDateTime(date, 'ZZ');

    return `${date.slice(0, -5)}${timezone}`;
};

export default dateAccountedToWinterTime;
