
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import system from '@/modules/System';
import calendar from '@/modules/Calendar';
import { Get } from '@/utils/vuex-module-mutators';
import { CALENDAR_TIME_FRAME_OPTIONS } from '@/modules/Calendar/constants';
import Controls from './Calendar/Controls/index.vue';
import CalendarContent from './Calendar/Content/index.vue';

@Component({
    components: {
        Controls,
        CalendarContent,
    },
})
export default class Events extends Vue {
    @Get(system) private screenType!: string;

    @Get(calendar) private calendarTimeFrame!: string;

    get displayPadding(): boolean {
        return this.screenType !== 'desktop'
         && this.calendarTimeFrame === CALENDAR_TIME_FRAME_OPTIONS.day;
    }
}
