import API from '@/api/graphQL';
import { InviteAmbassadorParamsType } from '@/api/graphQL/graphNodes/InviteAmbassadorQuery';

export default class InviteRepository {
    static inviteAmbassador(data: InviteAmbassadorParamsType) {
        return API
            .inviteAmbassador()
            .query(data);
    }

    static getInvitationUrl() {
        return API
            .getInviteUrl()
            .query();
    }
}
