import API from '@/api/graphQL';
import { GetHelpAndSupportAssetsParamsType } from '@/api/graphQL/graphNodes/GetHelpAndSupportAssetsQuery';
import { GetHelpAndSupportHostessAssetsParamsType } from '@/api/graphQL/graphNodes/GetHelpAndSupportHostessAssetsQuery';

export default class HelpAndSupportRepository {
    static getSponsor() {
        return API
            .getSponsor()
            .query();
    }

    static getAssets(params: GetHelpAndSupportAssetsParamsType) {
        return API
            .getHelpAndSupportAssets()
            .query(params);
    }

    static getHostessAssets(params: GetHelpAndSupportHostessAssetsParamsType) {
        return API
            .getHelpAndSupportHostessAssets()
            .query(params);
    }
}
