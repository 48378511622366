
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import LinkInvite from '@/projectComponents/linkInvite/index.vue';

@Component({ components: { LinkInvite } })
export default class DashboardLinkInvite extends Vue {
    @Get(profile) private shopUrl!: string;
}
