import { AssetStatsType, AssetType } from '@/api/graphQL/graphNodes/types';

export const assetFileResolver: (type: string) => string = (type) => {
    if (type === 'application/pdf') {
        return 'pdf';
    }

    if (type === 'external/video') {
        return 'video';
    }

    if (type === 'external/link') {
        return 'externalFile';
    }

    if (/image/i.test(type)) {
        return 'image';
    }

    if (/video/i.test(type)) {
        return 'videoFile';
    }

    return '';
};

export const getType: (file: AssetType) => undefined|AssetStatsType = (file) => file
    .context.find((item) => item.metric === 'Content-Type');

export const assetIconResolver: (file: AssetType) => string = (file) => {
    const type = getType(file);

    if (type) {
        const assetType: string = assetFileResolver(type.value);
        if (assetType === 'pdf') {
            return 'picture_as_pdf';
        }

        if (assetType === 'video' || assetType === 'videoFile') {
            return 'play_arrow';
        }

        if (assetType === 'image') {
            return 'panorama';
        }
    }

    return 'insert_drive_file';
};
