import { FIELD_REQUIRED_MESSAGE } from '@/utils/messages/formValidation';
import { DriverLicenseType } from '@/api/graphQL/graphNodes/types';
import { ValidationErrorType } from '../../types';
import { DRIVER_LICENSE_FORMAT } from '../messages';

const registrationDriverLicenseFormValidation:
    (val: DriverLicenseType) =>
    ValidationErrorType[] = (val) => {
        const errors: ValidationErrorType[] = [];

        if (!val.drivingLicense) {
            errors.push({ key: 'drivingLicense', val: FIELD_REQUIRED_MESSAGE });
        }

        if (val.drivingLicense && val.drivingLicense.length !== 16) {
            errors.push({ key: 'drivingLicense', val: DRIVER_LICENSE_FORMAT });
        }

        return errors;
    };

export default registrationDriverLicenseFormValidation;
