import { InvitationType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class RespondToInvitationQuery extends
    AbstractQueryResource<RespondToInvitationResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($eventId: String!, $attending: String!) {
            respondToInvitation(eventId: $eventId, attending: $attending) {
                id,
                invitedBy {
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                },
                enrollee {
                    shadow
                },
                attending
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'respondToInvitation';
    }
}

export type RespondToInvitationResultType = {
    respondToInvitation: InvitationType
}

export type RespondToInvitationParamsType = {
    eventId: string;
    attending: string;
}
