
import { Component, Vue } from 'vue-property-decorator';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { Get } from '@/utils/vuex-module-mutators';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UITabs extends Vue {
    @Get(componentsSandbox) private activeTab!: string;

    tabChanged(val: string) {
        componentsSandbox.setActiveTab(val);
    }
}
