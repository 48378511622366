import {
    Module, VuexModule, Mutation, getModule,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { SnackbarType } from './types';
import { defaultSnackbarTime, intervalBetweenSnackbars } from './defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'componentsControls',
})
@AutoMutations
export class ComponentsControls extends VuexModule {
    inlineEditActionType: string = '';

    snackbarMessages: SnackbarType[] = [];

    drawerOpened: boolean = false;

    displaySnackbar: SnackbarType|null = null;

    swipeFrom: number = 0;

    @Mutation
    public setInlineEditType(type: string): void {
        this.inlineEditActionType = type;
    }

    @Mutation
    public setInlineEditActionType(val: string) {
        this.inlineEditActionType = val;
    }

    @Mutation
    private addSnackbarMessage(message: SnackbarType) {
        this.snackbarMessages.push(message);
    }

    @Mutation
    private removeSnackbarMessage() {
        this.snackbarMessages.splice(0, 1);
    }

    @Mutation
    public toggleDrawerOpen() {
        this.drawerOpened = !this.drawerOpened;
    }

    @Mutation
    private setDisplaySnackbar(val: SnackbarType|null) {
        this.displaySnackbar = val;
    }

    @Mutation
    public setSwipeFrom(val: number) {
        this.swipeFrom = val;
    }

    @Action()
    public showSuccessMessage(
        { message, time = defaultSnackbarTime }: { message: string, time?: number },
    ) {
        const snackbar = { message, type: 'success', time };
        this.addSnackbarMessage(snackbar);
        if (!this.displaySnackbar) {
            this.setDisplaySnackbar(snackbar);
            if (time > 0) {
                this.removeMessageAfter(time);
            }
        }
    }

    @Action()
    public showErrorMessage(
        { message, time = defaultSnackbarTime }: { message: string, time?: number },
    ) {
        const snackbar = { message, type: 'error', time };
        this.addSnackbarMessage(snackbar);
        if (!this.displaySnackbar) {
            this.setDisplaySnackbar(snackbar);
            if (time > 0) {
                this.removeMessageAfter(time);
            }
        }
    }

    @Action()
    private removeMessageAfter(time: number) {
        setTimeout(() => {
            this.setDisplaySnackbar(null);
            this.removeSnackbarMessage();

            setTimeout(() => {
                if (this.snackbarMessages.length > 0) {
                    this.setDisplaySnackbar(this.snackbarMessages[0]);
                    this.removeMessageAfter(this.snackbarMessages[0].time);
                }
            }, intervalBetweenSnackbars);
        }, time);
    }

    @Action()
    public showNextMessage(
        { message, type, time = defaultSnackbarTime }
        : { message: string, type: string, time?: number },
    ) {
        if (this.displaySnackbar && this.displaySnackbar.time < 1) {
            this.setDisplaySnackbar(null);
            this.removeSnackbarMessage();
        }
        this.handleMessageShow({ message, type, time });
    }

    @Action()
    private handleMessageShow(
        { message, type, time = defaultSnackbarTime }
        : { message: string, type: string, time?: number },
    ) {
        switch (type) {
        case 'success':
            this.showSuccessMessage({ message, time });
            break;
        case 'error':
            this.showErrorMessage({ message, time });
            break;
        default:
            break;
        }
    }

    @Action()
    public handleSwipe(data: { end: number, swipeLength: number }) {
        const { end, swipeLength } = data;
        const length = end - this.swipeFrom;

        this.setSwipeFrom(0);

        if (length < swipeLength && length > -swipeLength) {
            return 0;
        }

        return length > 0 ? -1 : 1;
    }
}

export default getModule(ComponentsControls);
