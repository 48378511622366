
import { Vue, Component, Prop } from 'vue-property-decorator';
import { InvitationToEventType } from '@/api/graphQL/graphNodes/types';
import { EVENT_ATTENDANCE_STATUS } from '@/modules/Event/constants';
import eventView from '@/modules/Event/EventView';
import GuestDetails from './guestDetails.vue';

@Component({
    components: {
        GuestDetails,
    },
})
export default class EventViewGuests extends Vue {
    @Prop({ required: true }) private guests!: InvitationToEventType[];

    get sortedGuests() {
        return this.guests
            .sort((a, b) => (Object
                .keys(EVENT_ATTENDANCE_STATUS)
                .findIndex((key) => EVENT_ATTENDANCE_STATUS[key] === a.attending)
            < Object
                .keys(EVENT_ATTENDANCE_STATUS)
                .findIndex((key) => EVENT_ATTENDANCE_STATUS[key] === b.attending)
                ? 1 : -1));
    }

    handleDisplayCustomer(invitation: InvitationToEventType) {
        eventView.setDisplayCustomer(invitation.enrollee);
        eventView.setDisplayCustomerDetails(true);
    }
}
