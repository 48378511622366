
import Vue from 'vue';
import Component from 'vue-class-component';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';

@Component
export default class ErrorPage extends Vue {
    get errorText() {
        return ' Head back to main page or logout';
    }

    beforeDestroy() {
        ErrorsProcessor.clearErrors();
    }
}
