
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIDialogs extends Vue {
    @Get(componentsSandbox) defaultDialogOpen!: boolean;

    @Get(componentsSandbox) customDialogOpen!: boolean;

    handleDefaultDialog() {
        componentsSandbox.toggleDefaultDialogOpen();
    }

    handleCustomDialog() {
        componentsSandbox.toggleCustomDialogOpen();
    }
}
