
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import calendar from '@/modules/Calendar';
import { CalendarDayObject } from '@/modules/Calendar/Events/types';
import { EventType } from '@/api/graphQL/graphNodes/types';
import { Get } from '@/utils/vuex-module-mutators';
import Event from './Event/index.vue';

@Component({
    components: {
        Event,
    },
})
export default class DayView extends Vue {
    @Get(calendar) private isLoadingInBackground!: boolean;

    get displayLoadMore(): boolean {
        return this.events.length < this.currentDayTotal;
    }

    get currentDayTotal(): number {
        return calendar.currentDayTotal;
    }

    get day():CalendarDayObject {
        return calendar.getDayEvents;
    }

    get events(): EventType[] {
        return this.day.events;
    }

    async loadMoreEvents() {
        await calendar.loadAction({ offset: this.events.length, inBackground: true });
    }
}
