import dateManager from '@/utils/time';
import { findEvents } from './events';
import { ResolverCalendarType } from '../types';

const getTimeFrameDayDates: (from: string, to: string) => string[] = (from, to) => {
    const length = dateManager.getDifference(from, to, 'day');

    const res: string[] = [];

    for (let i = 0; i ! <= length; i++) {
        res.push(dateManager
            .getDateWithOffset(i, from, dateManager.getDateFormat()));
    }

    return res;
};

const calendar: (dateFrom: string, dateTo: string, offset: number, limit: number,
    type: string) => ResolverCalendarType[] = (dateFrom, dateTo, offset, limit, type) => {
        const days: string[] = getTimeFrameDayDates(dateFrom, dateTo);

        const res: ResolverCalendarType[] = days.map((day) => {
            const events = findEvents(day, offset, limit, type);

            return {
                day,
                totalEvents: events.length,
                events,
            };
        });

        return res;
    };

export { calendar };
