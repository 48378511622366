
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class VideoViewer extends Vue {
    @Prop({ required: true }) private src!: string;

    get url() {
        if (this.src.includes('vimeo.com')) {
            const code = this.src.split('/').reverse()
                .splice(0, 1);
            return `https://player.vimeo.com/video/${code}`;
        }

        return this.src;
    }

    get elementClasses() {
        return [
            'pts-layout pts-gutter',
        ];
    }
}
