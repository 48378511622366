const registerPassportInput = `input RegisterPassportInput {
    passport: String!,
}`;

const registerDriverLicenseInput = `input RegisterDriverLicenseInput {
    drivingLicense: String!,
}`;

const registerLexisNexisMutations = `
    registerDriverLicense(data: RegisterDriverLicenseInput): Enrollee!
    registerPassport(data: RegisterPassportInput): Enrollee!
`;

export { registerPassportInput, registerDriverLicenseInput, registerLexisNexisMutations };
