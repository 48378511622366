
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UILayout extends Vue {
    @Prop() route!: string;

    @Prop() title!: string;

    @Prop() id!: string;
}
