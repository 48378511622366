const routerInstancesRemover = (elem) => {
    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        instances, ...elemData
    } = elem;

    return elemData;
};

const routerParentsObjectProcessor = (elem) => {
    // eslint-disable-next-line prefer-const
    let { parent, ...elemData } = elem;

    if (parent) {
        const parentData = routerInstancesRemover(parent);

        parent = routerParentsObjectProcessor(parentData);
    }

    return { ...elemData, parent };
};

export { routerInstancesRemover, routerParentsObjectProcessor };
