
import { Vue, Component } from 'vue-property-decorator';
import expandableItem from '@/modules/ComponentsSandbox/ExpandableItem';
import { Sync } from '@/utils/vuex-module-mutators';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIExpansions extends Vue {
    @Sync(expandableItem) defaultExpanded!: boolean;

    @Sync(expandableItem) singleExpanded!: string;

    @Sync(expandableItem) multipleExpanded!: string[];

    @Sync(expandableItem) multilineHeaderExpanded!: boolean;

    @Sync(expandableItem) withIconExpanded!: boolean;

    @Sync(expandableItem) multilineHeaderWithIconExpanded!: boolean;

    @Sync(expandableItem) multilineHeaderWithIconAndAdditionalElementExpanded!: boolean;

    get getSingleExpanded1() {
        return this.singleExpanded === '1';
    }

    get getSingleExpanded2() {
        return this.singleExpanded === '2';
    }

    get getSingleExpanded3() {
        return this.singleExpanded === '3';
    }

    get getMultipleExpanded1() {
        return !!this.multipleExpanded.find((item) => item === '1');
    }

    get getMultipleExpanded2(): boolean {
        return !!this.multipleExpanded.find((item) => item === '2');
    }

    get getMultipleExpanded3() {
        return !!this.multipleExpanded.find((item) => item === '3');
    }

    toggleSingleExpanded(val: string) {
        expandableItem.toggleSingleExpanded(val);
    }

    toggleMultipleExpanded(val: string) {
        expandableItem.toggleMultipleExpanded(val);
    }
}
