
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import incomeSummary from '@/modules/IncomeSummary';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import FileViewer from '@/projectComponents/fileViewer/index.vue';
import authenticator from '@/modules/Auth/services/authenticator';

@Component({
    components: {
        FileViewer,
    },
})
export default class IncomeSummaryAssetsPreview extends Vue {
    @Get(incomeSummary) private previewFileId!: string;

    get token(): string {
        return authenticator.authTokenFromStorage || '';
    }

    get fileList(): AssetType[] {
        return incomeSummary.fileList;
    }

    get file(): AssetType|null {
        const res = this.fileList.find((file) => file.id === this.previewFileId);
        return res || null;
    }
}
