

import { Get } from '@/utils/vuex-module-mutators';
import { Component, Vue } from 'vue-property-decorator';
import calendar from '@/modules/Calendar';
import auth from '@/modules/Auth';

@Component
export default class TypeFilter extends Vue {
    @Get(calendar) private eventsTypeFilter! : string;

    @Get(calendar) isLoading!: boolean;

    get organicationalType() {
        return calendar.getOrganisationalEventType;
    }

    get personalType() {
        return calendar.getPersonalEventType;
    }

    get isAmbassador() {
        return auth.isAmbassador;
    }

    handleToggle(value) {
        calendar.handleEventsTypeFilter(value);
    }
}
