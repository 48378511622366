import { AssetType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetHelpAndSupportAssetsQuery
    extends AbstractQueryResource<GetHelpAndSupportAssetsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($query: String!, $category: String!, $limit: Int, $offset: Int) {
            searchAssets(query: $query, category: $category, limit: $limit, offset: $offset) {
                id,
                category,
                link,
                fileName,
                context {
                    metric,
                    value,
                },
                title,
                originalFileName,
                uploadDate,
            },
            searchAssetsCount(query: $query, category: $category)
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getHelpAndSupportAssets';
    }
}

export type GetHelpAndSupportAssetsParamsType = {
    query: string;
    category: string;
    limit: number;
    offset: number;
}

export type GetHelpAndSupportAssetsResultType = {
    searchAssets: AssetType[],
    searchAssetsCount: number,
}
