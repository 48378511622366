
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { FileType, FileAreaCategoryType } from './types';

@Component
export default class FileArea extends Vue {
    @Prop({ default: 'Documents' }) private title!: string;

    @Prop({ default: '' }) private name!: string;

    @Prop({ required: true }) private id!: string;

    @Prop({ required: true }) private value!: FileType[];

    @Prop({ default: '' }) private actionIcon!: string;

    @Prop({ required: true }) private categories!: FileAreaCategoryType[];

    @Prop({ default: () => () => null }) private downloadNameGenerator!: (arg1) => string|null

    private selectedCategory: string|null = null;

    get firstCategory(): string {
        const category = this.categories[0];
        if (category) {
            return String(category.value);
        }
        return '';
    }

    get elementClasses() {
        return [
            'FileArea__Container',
        ];
    }

    handleAreaClick() {
        // @ts-ignore
        this.$refs.fileUploadArea.handleAreaClick();
    }

    handleCategoryChange(val: string) {
        this.selectedCategory = val;
    }

    emitValues(name: string, value: any) {
        this.$emit(name, value);
    }

    mounted() {
        this.handleCategoryChange(this.firstCategory);
    }
}
