import { enrollee } from './enrollee';
import { ResolverEnrolleeType } from '../types';
import getToken from '../services/tokenResolver';

const getBreadcrumbs: (id:string) => ResolverEnrolleeType[] = (id) => {
    let res: ResolverEnrolleeType[] = [];
    const item: ResolverEnrolleeType = enrollee(id);
    if (item) {
        res.push(item);

        if (item.parentId && item.parentId !== enrollee(getToken()).parentId) {
            res = [...res, ...getBreadcrumbs(item.parentId)];
        }
    }

    return res;
};

const enrolleeBreadcrumbs: (id: string) => ResolverEnrolleeType[] = (id) => getBreadcrumbs(id);

export { enrolleeBreadcrumbs };
