import { validateEmail } from '@/utils/email-validation';
import { FIELD_REQUIRED_MESSAGE, INVALID_EMAIL_ADDRESS } from '@/utils/messages/formValidation';
import { RegistrationFormType } from '../types';
import { ValidationErrorType } from '../../types';

const registrationFormValidation:
    (val: RegistrationFormType) => ValidationErrorType[] = (val) => {
        const errors: ValidationErrorType[] = [];

        if (!val.email) {
            errors.push({ key: 'email', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!validateEmail(val.email)) {
            errors.push({ key: 'email', val: INVALID_EMAIL_ADDRESS });
        }

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        return errors;
    };

export default registrationFormValidation;
