
import Vue from 'vue';
import Component from 'vue-class-component';
import authenticator from '@/modules/Auth/services/authenticator';

@Component
export default class Logout extends Vue {
    async created() {
        await authenticator.logout();
    }
}
