
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class CheckedStatusIcon extends Vue {
    @Prop({ required: true }) private status!: boolean;

    get classes() {
        return [
            'CheckedStatusIcon__Element',
            { CheckedStatusIcon__DoneIcon: this.status },
        ];
    }
}
