
import { Vue, Component, Prop } from 'vue-property-decorator';
import HostessMe from '@/views/Events/Components/HostContent/hostessMe.vue';
import KnownHostess from '@/views/Events/Components/HostContent/knownHostess.vue';
import NewHostess from '@/views/Events/Components/HostContent/newHostess.vue';
import eventEdit, { EVENT_HOSTESS_SELECT_STEP } from '@/modules/Event/EventEdit';

@Component({
    components: {
        HostessMe,
        KnownHostess,
        NewHostess,
    },
})
export default class SelectHostessType extends Vue {
    @Prop({ required: true }) private isMe!: boolean;

    @Prop({ required: true }) private isNew!: boolean;

    selectMe() {
        eventEdit.setHostessMe();
        eventEdit.closeModalAfterEdit();
    }

    selectKnownHostess() {
        eventEdit.setHostessSelectStep(EVENT_HOSTESS_SELECT_STEP.known);
    }

    selectNewHostess() {
        eventEdit.setHostessSelectStep(EVENT_HOSTESS_SELECT_STEP.new);
        eventEdit.clearHostessData();
    }
}
