import { SelectOptionsType } from '@/components/select/types';

export const timezoneOptions: SelectOptionsType[] = [
    { name: 'PST', value: '-0800' },
    { name: 'MST', value: '-0700' },
    { name: 'CST', value: '-0600' },
    { name: 'EST', value: '-0500' },
    { name: 'UTC-04:00', value: '-0400' },
    { name: 'UTC-03:30', value: '-0330' },
    { name: 'UTC-03:00', value: '-0300' },
    { name: 'UTC-02:00', value: '-0200' },
    { name: 'UTC-01:00', value: '-0100' },
    { name: 'UTC+00:00', value: '+0000' },
    { name: 'UTC+01:00', value: '+0100' },
    { name: 'UTC+02:00', value: '+0200' },
    { name: 'UTC+03:00', value: '+0300' },
    { name: 'UTC+03:30', value: '+0330' },
    { name: 'UTC+04:00', value: '+0400' },
    { name: 'UTC+04:30', value: '+0430' },
    { name: 'UTC+05:00', value: '+0500' },
    { name: 'UTC+05:30', value: '+0530' },
    { name: 'UTC+05:45', value: '+0545' },
    { name: 'UTC+06:00', value: '+0600' },
    { name: 'UTC+06:30', value: '+0630' },
    { name: 'UTC+07:00', value: '+0700' },
    { name: 'UTC+08:00', value: '+0800' },
    { name: 'UTC+08:45', value: '+0845' },
    { name: 'UTC+09:00', value: '+0900' },
    { name: 'UTC+09:30', value: '+0930' },
    { name: 'UTC+10:00', value: '+1000' },
    { name: 'UTC+10:30', value: '+1030' },
    { name: 'UTC+11:00', value: '+1100' },
    { name: 'UTC+12:00', value: '+1200' },
    { name: 'UTC+12:45', value: '+1245' },
    { name: 'UTC+13:00', value: '+1300' },
    { name: 'UTC+14:00', value: '+1400' },
    { name: 'UTC-12:00', value: '-1200' },
    { name: 'UTC-11:00', value: '-1100' },
    { name: 'UTC-10:00', value: '-1000' },
    { name: 'UTC-09:30', value: '-0930' },
    { name: 'UTC-09:00', value: '-0900' },
];
