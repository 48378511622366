// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { InvitationType } from './types';

export default class GetInvitationForEventQuery extends
    AbstractQueryResource<GetInvitationForEventResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($eventId: String!) {
            invitationForEvent(eventId: $eventId) {
                id,
                email,
                address,
                name,
                phoneNumber,
                invitedBy {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                },
                enrollee {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                },
                attending,
                type
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'invitationForEvent';
    }
}

export type GetInvitationForEventResultType = {
    invitationForEvent: InvitationType;
}
