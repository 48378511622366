
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import settings from '@/modules/Settings';

@Component
export default class Camera extends Vue {
    @Prop({ required: true }) private image!: string;

    @Prop({ required: true }) private cameraLoading!: boolean;

    @Prop({ required: true }) private isPhotoTaken!: boolean;

    @Prop({ required: true }) private isCameraLoading!: boolean;

    @Prop({ required: true }) private displayWidth!: number;

    @Prop({ required: true }) private displayHeight!: number;

    @Prop({ required: true }) private cameraNotPermitted!: boolean;

    @Prop({ required: true }) private cameraDialogOpen!: boolean;

    @Prop({ required: true }) private cameraWidth!: number;

    @Prop({ required: true }) private cameraHeight!: number;

    @Get(system) private screenType!: string;

    @Get(settings) private cameraMaxSizeMobile!: number;

    @Get(settings) private cameraMaxSizeTablet!: number;

    @Get(settings) private cameraMaxSizeDesktop!: number;

    get loaderStyle() {
        return {
            width: `${this.displayWidth}px`,
            height: `${this.displayHeight + 48}px`,
        };
    }

    get noImage(): boolean {
        return this.image === '';
    }

    get maxSize(): number {
        const { screenType } = this;

        if (screenType === 'mobile') {
            return this.cameraMaxSizeMobile;
        }

        if (screenType === 'tablet') {
            return this.cameraMaxSizeTablet;
        }

        return this.cameraMaxSizeDesktop;
    }

    beforeDestroy() {
        this.stopCameraStream();
    }

    handleProfileUpload() {
        this.$emit('handleCameraUpload');
    }

    handleCameraModalClose() {
        this.$emit('handleCameraModalClose');
    }

    takePhoto() {
        this.$emit('takePhoto');
    }

    getPhotoRef(value: boolean) {
        // @ts-ignore
        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, this.displayWidth, this.displayHeight);
        // @ts-ignore
        if (value) {
            // @ts-ignore
            return this.$refs.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
        }
        return '';
    }

    getCroppedImg() {
        // @ts-ignore
        return this.$refs.cropArea.$refs.cropper.getCroppedCanvas().toDataURL();
    }

    stopCameraStream() {
        // @ts-ignore
        if (this.$refs.camera && this.$refs.camera.srcObject) {
            // @ts-ignore
            const tracks = this.$refs.camera.srcObject.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });
        }
    }

    handleResolutionChange() {
        this.setFittingResolution();
    }

    setFittingResolution(width: null|number = null, height: null|number = null) {
        const usableWidth = width || this.cameraWidth;
        const usableHeight = height || this.cameraHeight;

        if (usableWidth > usableHeight) {
            const multiplier = usableWidth / this.maxSize;
            const widthDimensions = {
                width: this.maxSize, height: Math.floor(usableHeight / multiplier),
            };

            this.$emit('setDimensions', widthDimensions);
        } else {
            const multiplier = usableHeight / this.maxSize;
            const heightDimensions = {
                height: this.maxSize, width: Math.floor(usableWidth / multiplier),
            };

            this.$emit('setDimensions', heightDimensions);
        }
    }

    @Watch('screenType')
    drawImage() {
        const newImage = new Image();

        if (this.$refs.canvas) {
            // @ts-ignore
            const context = this.$refs.canvas.getContext('2d');

            // @ts-ignore
            newImage.onload = new Promise(() => {
                context.drawImage(newImage, 0, 0);
                newImage.src = this.image;
            });
        }
    }

    @Watch('cameraDialogOpen')
    cameraDialogOpenChange() {
        this.handleResolutionChange();
        if (!this.cameraDialogOpen) {
            // @ts-ignore
            this.stopCameraStream();
        }
    }
}
