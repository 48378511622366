
import { Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import Vue from 'vue';
import calendar from '@/modules/Calendar';
import system from '@/modules/System';
import dateManager from '@/utils/time';
import Week from '../Week/index.vue';
import WeekDays from '../WeekDays/index.vue';

@Component({
    components: {
        Week,
        WeekDays,
    },
})
export default class MonthView extends Vue {
    @Get(calendar) private calendarTimeFrame!: string;

    @Get(system) private screenType!: string;

    get numberOfWeeks(): number {
        return dateManager.getDifference(
            calendar.getCalendarStart,
            dateManager.getDateWithOffset(1, calendar.getCalendarEnd),
            'w',
        );
    }

    get month(): (string[])[] {
        const timeFrameDates: string[] = [...calendar.getTimeFrameDayDates];
        const result: (string[])[] = [];
        for (let i = 0; i < this.numberOfWeeks; i++) {
            const item: string[] = timeFrameDates.splice(0, 7);
            result.push(item);
        }

        return result;
    }
}
