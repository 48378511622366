
import { Vue, Component } from 'vue-property-decorator';
import { Sync } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { TEXT_FIELD_DISPLAY_TYPE } from '@/components/textField/constants';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UITextFields extends Vue {
    @Sync(componentsSandbox) name!: string;

    @Sync(componentsSandbox) iban!: string;

    get ibanDisplay() {
        return TEXT_FIELD_DISPLAY_TYPE.iban;
    }
}
