import { InvitationType } from '@/api/graphQL/graphNodes/types';
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { EventInviteType } from './types';

const initialInviteData: () => EventInviteType = () => ({
    firstName: '',
    lastName: '',
    email: '',
    captcha: '',
});

const initialEventInvitationData: () => InvitationType = () => ({
    id: '',
    address: '',
    attending: null,
    email: '',
    enrollee: defaultEnrollee(),
    invitedAt: '',
    invitedBy: defaultEnrollee(),
    name: '',
    phoneNumber: '',
    respondedAt: '',
    type: '',
});

export { initialInviteData, initialEventInvitationData };
