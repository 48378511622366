
import Vue from 'vue';
import Component from 'vue-class-component';
import { Get } from '@/utils/vuex-module-mutators';
import authModule from '@/modules/Auth/index';

@Component
export default class AuthError extends Vue {
    @Get(authModule) authFailMessage!: string;

    @Get(authModule) authFailMode!: string;

    @Get(authModule) authFailContacts!: Array<string>;

    get errorText() {
        const wrappedContacts = this.authFailContacts.map(
            (contact) => `<a href="mailto:${contact}">${contact}</a>`,
        );
        if (this.authFailMode === 'tenant') {
            return `Company <b>"${this.authFailMessage}"</b> is currently inaccessible, `
            + 'for more information contact the owners at one of the following '
            + `emails: <br>${wrappedContacts.join(', ')}.`;
        } if (this.authFailMode === 'accountDisabled') {
            return `Your account has no access to <b>"${this.authFailMessage}"</b> `
            + 'company. For more details contact an administrator at one of the '
            + `following emails: <br>${wrappedContacts.join(', ')}.`;
        }
        return 'Access denied.';
    }
}
