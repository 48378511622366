export const mapSettings = {
    center: { lat: 37.189419, lng: -98.273420 },
    clickableIcons: false,
    streetViewControl: false,
    panControlOptions: false,
    gestureHandling: 'cooperative',
    mapTypeControl: true,
    zoomControlOptions: {
        style: 'SMALL',
    },
    zoom: 4,
    minZoom: 2,
    maxZoom: 20,
};
