// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { PassportType } from './types';

export default class RegisterAmbassadorPassportQuery extends
    AbstractQueryResource<RegisterAmbassadorPassportResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($registerPassport: RegisterPassportInput) {
            registerPassport(data: $registerPassport) {
                lexisNexisStatus,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'registerAmbassadorPassport';
    }
}

export type RegisterAmbassadorPassportParamsType = {
    registerPassport: PassportType;
}

export type RegisterAmbassadorPassportResultType = {
    registerPassport: {
        lexisNexisStatus: string|null
    };
}
