// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class ShareEventQuery extends
    AbstractQueryResource<ShareEventResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String!) {
            shareEvent(id: $id)
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'shareEvent';
    }
}

export type ShareEventResultType = {
    shareEvent: string;
}

export type ShareEventParamsType = {
    id: string;
}
