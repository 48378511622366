
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import eventEdit from '@/modules/Event/EventEdit';
import eventHostess from '@/modules/Event/Hostesses';
import { MyHostsType } from '@/api/graphQL/graphNodes/types';
import KnownHostess from '@/views/Events/Components/HostContent/knownHostess.vue';
import HostessSelect from '@/views/Events/Components/HostContent/hostessSelect.vue';

@Component({
    components: {
        KnownHostess,
        HostessSelect,
    },
})
export default class EventEditKnownHostessList extends Vue {
    @Get(eventHostess) private hostesses!: MyHostsType[];

    @Sync(eventEdit) private filter!: string;

    get filteredList() {
        return this.hostesses.filter((hostess) => (`${hostess.firstName} ${hostess.lastName}`)
            .toLowerCase()
            .includes(this.filter.toLowerCase()))
            .sort((a, b) => (`${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1));
    }

    get groupedList() {
        return this.filteredList.reduce((groups, hostess) => {
            const groupName = (`${hostess.firstName} ${hostess.lastName}`).charAt(0);
            if (!groups[groupName]) {
                // eslint-disable-next-line no-param-reassign
                groups[groupName] = [];
            }
            const group = groups[groupName];
            group.push(hostess);

            return groups;
        }, {});
    }

    handleSelect(hostess: MyHostsType) {
        eventEdit.setHostessToEvent(hostess);
        eventEdit.closeModalAfterEdit();
    }
}
