import { AmbassadorRegisterInviteType } from '@/api/graphQL/graphNodes/types';

const initialRegistrationData: () => AmbassadorRegisterInviteType = () => ({
    firstName: '',
    lastName: '',
    email: '',
    captcha: '',
});

export { initialRegistrationData };
