import { ResolverProductType } from '../types';

const products: ResolverProductType[] = [
    {
        id: '1',
        referenceNumber: 'AS-1',
        title: 'Whisper Rabbit Vibrator',
        price: 25.98,
    },
    {
        id: '2',
        referenceNumber: 'AS-2',
        title: 'Knickerbox Planet -The Charmer Body',
        price: 17.55,
    },
    {
        id: '3',
        referenceNumber: 'AS-3',
        title: 'Addiction Dress',
        price: 399.99,
    },
    {
        id: '4',
        referenceNumber: 'AS-4',
        title: 'My Viv Oil-based Lubricant',
        price: 30.89,
    },
    {
        id: '5',
        referenceNumber: 'AS-5',
        title: 'The Dark Hours Chemise',
        price: 21.04,
    },
    {
        id: '6',
        referenceNumber: 'AS-6',
        title: 'Anise Lace Dress',
        price: 36.00,
    },
    {
        id: '7',
        referenceNumber: 'AS-7',
        title: 'Bijoux 12 Sexy Days Calendar',
        price: 130.00,
    },
    {
        id: '8',
        referenceNumber: 'AS-8',
        title: 'Adjustable Spreader Bar',
        price: 63.00,
    },
    {
        id: '9',
        referenceNumber: 'AS-9',
        title: 'The Ultimate Bondage 6 Piece Set',
        price: 76.00,
    },
    {
        id: '10',
        referenceNumber: 'AS-10',
        title: 'Tease Me, Leave Me Reversible Blindfold',
        price: 6.95,
    },
    {
        id: '11',
        referenceNumber: 'AS-11',
        title: 'The Heart Breaker Set',
        price: 44.00,
    },
];

const product: (id: string)
 => ResolverProductType = (id) => products.find((item) => item.id === id)!;

export { products, product };
