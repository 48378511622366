
import { Vue, Component } from 'vue-property-decorator';
import { Sync, Get } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { SelectOptionsType } from '@/components/select/types';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UISearchSelects extends Vue {
    @Sync(componentsSandbox) searchSelectInput!: string;

    @Get(componentsSandbox) searchSelectOptions!: SelectOptionsType[];

    @Get(componentsSandbox) searchSelectSelected!: string;

    get options() {
        return this.searchSelectOptions.filter((option) => option.name
            .toLowerCase()
            .includes(this.searchSelectInput.toLowerCase()));
    }

    handleSelect(val: string) {
        componentsSandbox.setSearchSelectSelected(val);
    }

    handleSubmit(val: string) {
        componentsSandbox.setSearchSelectSelected(val);
    }
}
