
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Loader extends Vue {
    @Prop({ default: '' }) private type!: string;

    get classes() {
        return [
            'Loader',
            { Loader__Tiny: this.type === 'tiny' },
        ];
    }
}
