
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';
import dateManager from '@/utils/time';
import { SelectOptionsType } from '../select/types';

@Component
export default class DatePickerYearSelectComponent extends Vue {
    @Prop() private value!: string;

    get getYears(): SelectOptionsType[] {
        const data = [{ name: '', value: '' }] as SelectOptionsType[];
        const today = Number(dateManager.getCurrentDate('YYYY'));
        for (let i = 1920; i <= today; i++) {
            data.push({ name: `${i}`, value: `${i}` });
        }
        return data.reverse();
    }

    handleYearUpdate(val: string) {
        this.$emit('onYearUpdate', val);
    }
}
