
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class OptionItemLayout extends Vue {
    @Prop({ default: false }) private active!: boolean;

    @Prop({ default: '' }) private caption!: string;

    @Prop({ required: true }) private title!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: '' }) private iconImageUrl!: string;

    @Prop({ default: false }) private disabled!: boolean;

    get classes(): string[] {
        return [
            'OptionItemLayout__OptionContainer',
            this.active ? 'OptionItemLayout__OptionContainer--active' : '',
            this.disabled ? 'OptionItemLayout__OptionContainer--disabled' : '',
        ];
    }

    get titleClasses(): string[] {
        return [
            this.caption === '' ? 'OptionItemLayout__NoCaptionTitle' : '',
        ];
    }

    handleClick() {
        if (!this.disabled) {
            this.$emit('click');
        }
    }
}
