import { CountriesType } from '@/modules/Event/types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetCountriesQuery extends AbstractQueryResource<GetCountriesResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            countries {
                shortLabel,
                longLabel,
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getCountries';
    }
}

export type GetCountriesResultType = {
    countries: CountriesType[];
}
