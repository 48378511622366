/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { Graph, UploadProfilePictureQuery } from '@/api/graphQL/internal';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { dataURLtoFile, resizeFile } from './services/imageResizeProcessor';
import profileRepository from './services/profileRepository';
import profile from './index';

@Module({
    namespaced: true, dynamic: true, store, name: 'profilePicture',
})
@AutoMutations
export class ProfilePicture extends VuexModule {
    private image: string = '';

    private maxSizeMobile: number = 300;

    private maxSizeTablet: number = 540;

    private maxSizeDesktop: number = 750;

    private displayWidth: number = 0;

    private displayHeight: number = 0;

    private cameraWidth: number = 0;

    private cameraHeight: number = 0;

    private isPhotoTaken: boolean = false;

    private isShotPhoto: boolean = false;

    private isCameraLoading: boolean = false;

    private cameraNotPermitted: boolean = false;

    private loading: boolean = false;

    @Mutation
    public setIsPhotoTaken(val: boolean) {
        this.isPhotoTaken = val;
    }

    @Mutation
    public setIsShotPhoto(val: boolean) {
        this.isShotPhoto = val;
    }

    @Mutation
    public setIsCameraLoading(val: boolean) {
        this.isCameraLoading = val;
    }

    @Mutation
    public setDisplayWidth(val: number) {
        this.displayWidth = val;
    }

    @Mutation
    public setDisplayHeight(val: number) {
        this.displayHeight = val;
    }

    @Mutation
    public setCameraWidth(val: number) {
        this.cameraWidth = val;
    }

    @Mutation
    public setCameraHeight(val: number) {
        this.cameraHeight = val;
    }

    @Mutation
    public setImage(val: string) {
        this.image = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    public setCameraNotPermitted(val: boolean) {
        this.cameraNotPermitted = val;
    }

    @Action()
    public async clearImageData() {
        this.setIsCameraLoading(false);
        this.setIsPhotoTaken(false);
        this.setIsShotPhoto(false);
        this.setImage('');
    }

    @Action()
    public async uploadProfilePicture(payload: { img: string }) {
        const { img } = payload;

        try {
            this.setLoading(true);

            const formData = new FormData();

            const uploadedFile = null;
            const profileQuery = new UploadProfilePictureQuery(new Graph());
            const query = profileQuery.getQuery();
            const operations = JSON.stringify({ query, variables: { uploadedFile } });
            formData.append('operations', operations);

            const map = {
                0: ['variables.uploadedFile'],
            };
            formData.append('map', JSON.stringify(map));

            const file = dataURLtoFile(await resizeFile(img), 'profile.jpeg');

            formData.append('0', file);

            const res = await profileRepository.uploadProfileImage(formData);

            profile.setProfilePicture(res.uploadProfilePicture);
            this.clearImageData();
            profile.setImageDialogOpen(false);
            profile.setCameraDialogOpen(false);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(ProfilePicture);
