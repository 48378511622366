import { ValidationErrorType } from '@/modules/types';
import { validateEmail } from '@/utils/email-validation';
import { FIELD_REQUIRED_MESSAGE, INVALID_EMAIL_ADDRESS } from '@/utils/messages/formValidation';
import { EventInviteType } from '../EventInvitation/types';

const eventInviteFormValidation: (form: EventInviteType) => ValidationErrorType[] = (form) => {
    const errors: ValidationErrorType[] = [];

    if (!form.firstName) {
        errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
    }
    if (!form.lastName) {
        errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
    }
    if (!form.email) {
        errors.push({ key: 'email', val: FIELD_REQUIRED_MESSAGE });
    }

    if (!validateEmail(form.email)) {
        errors.push({ key: 'email', val: INVALID_EMAIL_ADDRESS });
    }

    return errors;
};

export { eventInviteFormValidation };
