
import {
    Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import system from '@/modules/System';
import { Get } from '@/utils/vuex-module-mutators';

@Component
export default class Chart extends Vue {
    @Prop({ required: true }) private totalBooked!: number;

    @Prop({ required: true }) private completedLastMonth!: number;

    @Prop({ required: true }) private completedThisMonth!: number;

    @Get(system) private screenWidth!: number;

    get cx(): string {
        return '50%';
    }

    get cy(): string {
        return '0%';
    }

    get strokeWidth(): string {
        return '16';
    }

    get maxVal(): number {
        if (this.totalBooked === 0 && this.completedLastMonth === 0
         && this.completedThisMonth === 0) {
            return 1;
        }

        if (this.totalBooked === this.completedLastMonth
         && this.completedThisMonth === this.completedLastMonth) {
            return this.totalBooked;
        }

        if (this.completedThisMonth > this.completedLastMonth
         && this.completedThisMonth > this.totalBooked) {
            return this.completedThisMonth;
        }

        return this.totalBooked > this.completedLastMonth
            ? this.totalBooked : this.completedLastMonth;
    }

    get totalBookedPercentage(): number {
        if (this.totalBooked === 0 && this.completedLastMonth === 0
         && this.completedThisMonth === 0) {
            return 100;
        }

        return (this.totalBooked * 100) / this.maxVal;
    }

    get completedLastMonthPercentage(): number {
        return (this.completedLastMonth * 100) / this.maxVal;
    }

    get completedThisMonthPercentage(): number {
        return (this.completedThisMonth * 100) / this.maxVal;
    }

    circumference(r) {
        return r * 2 * Math.PI;
    }

    progress(percent, circumference): number {
        // eslint-disable-next-line no-mixed-operators
        const result = circumference - percent / 200 * circumference;
        return result;
    }

    setCircleR(ref, r) {
        ref.setAttribute('r', r);
    }

    setStroke(ref, circumference, progressVal) {
        // eslint-disable-next-line no-param-reassign
        ref.style.strokeDashoffset = this.progress(progressVal, circumference);
        // eslint-disable-next-line no-param-reassign
        ref.style.strokeDasharray = `${circumference}, ${circumference}`;
    }

    calculateRadius(): number {
        const elem: HTMLElement = this.$refs.svg as HTMLElement;
        const parent: HTMLElement = elem.parentNode!.parentNode as HTMLElement;
        const dimensions: DOMRect = parent.getBoundingClientRect();

        const width: number = dimensions.width * 0.5 > dimensions.height
            ? dimensions.height * 0.84 : dimensions.width * 0.42;

        return width;
    }

    @Watch('screenWidth')
    onScreenSizeChange() {
        this.initChart();
    }

    initChart() {
        const r: number = this.calculateRadius();
        const circumference: number = this.circumference(r);

        this.setCircleR(this.$refs.totalBooked, r);
        this.setCircleR(this.$refs.lastMonthCircle, r);
        this.setCircleR(this.$refs.thisMonthCircle, r);

        this.setStroke(
            this.$refs.totalBooked,
            circumference,
            this.totalBookedPercentage,
        );
        this.setStroke(
            this.$refs.lastMonthCircle,
            circumference,
            this.completedLastMonthPercentage,
        );
        this.setStroke(
            this.$refs.thisMonthCircle,
            circumference,
            this.completedThisMonthPercentage,
        );

        const svg:SVGElement = this.$refs.svg as SVGElement;
        svg.setAttribute('height', `${r + 10}`);
    }

    mounted() {
        this.initChart();
    }
}
