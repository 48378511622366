// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class CancelEventQuery extends
    AbstractQueryResource<CancelEventResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($id: String!) {
            cancelEvent(id: $id) {
                canceled,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'cancelEvent';
    }
}

export type CancelEventResultType = {
    cancelEvent: {
        canceled: boolean;
    }
}

export type CancelEventParamsType = {
    id: string;
}
