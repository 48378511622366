import { AmbassadorInvitationType } from '@/api/graphQL/graphNodes/types';
import { ValidationErrorType } from '@/modules/types';
import { FIELD_REQUIRED_MESSAGE, INVALID_EMAIL_ADDRESS } from '@/utils/messages/formValidation';
import { validateEmail } from '@/utils/email-validation';

const emailInviteFormValidation: (form: AmbassadorInvitationType)
    => ValidationErrorType[] = (form) => {
        const errors: ValidationErrorType[] = [];

        if (!form.email) {
            errors.push({ key: 'email', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!validateEmail(form.email)) {
            errors.push({ key: 'email', val: INVALID_EMAIL_ADDRESS });
        }

        if (!form.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!form.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        return errors;
    };

export { emailInviteFormValidation };
