// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { DriverLicenseType } from './types';

export default class RegisterAmbassadorDriverLicenseQuery extends
    AbstractQueryResource<RegisterAmbassadorDriverLicenseResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($registerDriverLicense: RegisterDriverLicenseInput) {
            registerDriverLicense(data: $registerDriverLicense) {
                lexisNexisStatus,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'registerAmbassadorDriverLicense';
    }
}

export type RegisterAmbassadorDriverLicenseParamsType = {
    registerDriverLicense: DriverLicenseType;
}

export type RegisterAmbassadorDriverLicenseResultType = {
    registerDriverLicense: {
        lexisNexisStatus: string|null
    };
}
