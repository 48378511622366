
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import system from '@/modules/System';
import { Get } from '@/utils/vuex-module-mutators';
import calendar from '@/modules/Calendar';
import { CALENDAR_TIME_FRAME_OPTIONS } from '@/modules/Calendar/constants';
import MobileDay from './MobileDay/index.vue';
import DesktopDay from './DesktopDay/index.vue';

@Component({
    components: {
        MobileDay,
        DesktopDay,
    },
})
export default class CalendarDay extends Vue {
    @Prop() private id!: string;

    @Prop() private showWeekDay!: boolean;

    @Get(system) private screenType!: string;

    @Get(calendar) private calendarDate!: string;

    get thisDay(): boolean {
        return this.calendarDate === this.id;
    }

    async openDay() {
        calendar.setScheduleDate(this.id);
        calendar.setScheduleTimeFrame(CALENDAR_TIME_FRAME_OPTIONS.day);
        await calendar.loadEvents();
    }
}
