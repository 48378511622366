
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';

@Component({
    components: {
        VueCropper,
    },
})
export default class ImageCrop extends Vue {
    @Prop({ required: true }) private src!: string;

    @Prop({ required: true }) private aspectRatio!: number;
}
