
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HydraPaginationType } from '@plumtreesystems/utils';

@Component
export default class PaginationHydra extends Vue {
    @Prop({ required: true }) private currentPage!: number;

    @Prop({ required: true }) private pagination!: HydraPaginationType;

    @Prop({ default: false }) private disabled!: boolean;

    get lastPage() {
        if (this.pagination.last) {
            return this.pagination.last;
        }
        return 1;
    }

    get isPrevDisabled() {
        if (this.pagination.first === null) {
            return true;
        }
        if (this.disabled) {
            return true;
        }
        return this.currentPage <= 1;
    }

    get isNextDisabled() {
        if (this.pagination.last === null) {
            return true;
        }
        if (this.disabled) {
            return true;
        }
        if (this.pagination.last) {
            return this.currentPage >= this.pagination.last;
        }
        return true;
    }

    onFirst() {
        this.$emit('onPageChange', 1);
    }

    onNext() {
        const targetPage = this.currentPage + 1;
        this.$emit('onPageChange', targetPage);
    }

    onPrev() {
        const targetPage = this.currentPage - 1;
        this.$emit('onPageChange', targetPage);
    }

    onLast() {
        if (this.pagination.last) {
            const targetPage = this.pagination.last;
            this.$emit('onPageChange', targetPage);
        }
    }
}
