
import { Vue, Component, Prop } from 'vue-property-decorator';
import { FileType } from '@/components/fileArea/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import Content from './content.vue';

@Component({
    components: {
        Content,
    },
})
export default class UploadDialogContent extends Vue {
    @Prop({ required: true }) private imageDialogOpen!: boolean;

    @Prop({ required: true }) private profilePicUpload!: FileType|null;

    @Prop({ required: true }) private image!: string;

    @Prop({ required: true }) private uploadLoading!: boolean;

    get noImage(): boolean {
        return this.image === '';
    }

    handleReupload() {
        this.$emit('handleReupload');
    }

    handleImageUploadModalClose() {
        this.$emit('handleImageUploadModalClose');
    }

    handleProfileUpload() {
        this.$emit('handleProfileUpload');
    }

    onFileAdd(files: FileType[]) {
        this.$emit('onFileAdd', files);
    }
}
