
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class EventViewCustomerSignUp extends Vue {
    handleSignupForm() {
        const { id } = this.$route.params;
        const token = this.$route.query.token.toString();

        this.$router.push({ name: 'customerRegistration', query: { token, event: id } });
    }
}
