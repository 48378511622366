
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import system from '@/modules/System';
import { Get } from '@/utils/vuex-module-mutators';
import dateManager from '@/utils/time';
import Day from '../Day/index.vue';

@Component({
    components: {
        Day,
    },
})
export default class CalendarWeek extends Vue {
    @Prop() private days!: string[];

    @Prop({ required: true }) private showWeekDay!: boolean;

    @Get(system) private screenType!: string;

    get weekNumber(): number {
        return dateManager.getWeekOfYear(this.days[0]);
    }
}
