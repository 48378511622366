import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';

@Module({
    namespaced: true, dynamic: true, store, name: 'componentsSandboxExpandableItem',
})
@AutoMutations
export class ComponentsSandboxExpandableItem extends VuexModule {
    defaultExpanded: boolean = false;

    singleExpanded: string = '';

    multilineHeaderExpanded: boolean = false;

    withIconExpanded: boolean = false;

    multilineHeaderWithIconExpanded: boolean = false;

    multilineHeaderWithIconAndAdditionalElementExpanded: boolean = false

    multipleExpanded: string[] = [];

    @Mutation
    toggleSingleExpanded(val: string) {
        if (val === this.singleExpanded) {
            this.singleExpanded = '';
        } else {
            this.singleExpanded = val;
        }
    }

    @Mutation
    toggleMultipleExpanded(val: string) {
        if (this.multipleExpanded.find((item) => item === val)) {
            const index = this.multipleExpanded.findIndex((item) => item === val);
            this.multipleExpanded.splice(index, 1);
        } else {
            this.multipleExpanded.push(val);
        }
    }
}

export default getModule(ComponentsSandboxExpandableItem);
