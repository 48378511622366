
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
    components: {},
})
export default class CalendarOverfull extends Vue {
    @Prop() private total!: number;
}
