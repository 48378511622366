import API from '@/api/graphQL';
import { GetImpersonateParamsType } from '@/api/graphQL/graphNodes/GetImpersonateQuery';
import authLocalStorageManager from '@/modules/Auth/services/authLocalStorageManager';

export default class ImpersonateRepository {
    static impersonate(data: GetImpersonateParamsType) {
        return API
            .getImpersonate()
            .query(data, { token: authLocalStorageManager.getAuthToken() });
    }
}
