import { EVENT_INVITATION_TYPE } from '@/modules/Event/constants';
import getToken from '../services/tokenResolver';
import { enrollee } from './enrollee';
import { events } from './events';
import { ResolverInvitationType } from '../types';

const defaultMockInvitation: ResolverInvitationType = {
    id: String(events.length),
    address: null,
    attending: '',
    email: '',
    invitedAt: '',
    invitedBy: enrollee(getToken()),
    enrollee: null,
    name: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    respondedAt: null,
    type: EVENT_INVITATION_TYPE.guest,
};

const getInvitation = (): ResolverInvitationType => defaultMockInvitation;

export default getInvitation;
