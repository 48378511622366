// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { RegisterHostessType, TermsAndConditionsType } from './types';

export default class GetCustomerRegistrationQuery
    extends AbstractQueryResource<GetCustomerRegistrationResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($type: String!) {
            profile {
                firstName,
                lastName,
                email
            },
            termsAndConditions(type: $type) {
                id,
                createdAt,
                content
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getCustomerRegistration';
    }
}

export type GetCustomerRegistrationResultType = {
    profile: RegisterHostessType;
    termsAndConditions: TermsAndConditionsType;
}

export type GetCustomerRegistrationParamsType = {
    type: string;
}
