import setings from '@/modules/Settings';
import setingsLoader from '@/modules/Settings/services/settingsLoader';

class InstallManager {
    onCancel() {
        setings.setShown(true);
        setings.setInstallReady(false);
        setingsLoader.save();
    }
}

export default new InstallManager();
