

import { Vue, Component } from 'vue-property-decorator';
import { Sync } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIProgressBar extends Vue {
    @Sync(componentsSandbox) progressBarValue!: string;
}
