
import { Vue, Prop, Component } from 'vue-property-decorator';
import { ActionMenuOptionType } from './types';

@Component
export default class ActionMenu extends Vue {
    @Prop({ default: 'auto' }) private mdSize!: string;

    @Prop({ default: 'bottom-start' }) private mdDirection!: string;

    @Prop({ default: 0 }) private offsetX!: number;

    @Prop({ default: 10 }) private offsetY!: number;

    @Prop({ required: true }) private options!: ActionMenuOptionType[];

    private handleActionSelect(index) {
        this.$emit('select', index);
    }
}
