var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('svg',{ref:"svg",staticClass:"DashboardMonthPerformanceChart__SVG"},[((_vm.completedLastMonth > _vm.completedThisMonth && _vm.totalBooked < _vm.completedThisMonth ) ||
        (_vm.totalBooked < _vm.completedThisMonth && _vm.completedThisMonth === _vm.completedLastMonth) ||
        (_vm.completedLastMonth === _vm.completedThisMonth && _vm.completedLastMonth === _vm.totalBooked) ||
        (_vm.completedLastMonth === 0 && _vm.completedThisMonth === 0 && _vm.totalBooked === 0) ||
        (_vm.completedThisMonth === _vm.totalBooked && _vm.totalBooked < _vm.completedLastMonth))?_c('circle',{ref:"lastMonthCircle",staticClass:"DashboardMonthPerformanceChart__Circle DashboardMonthPerformanceChart__ThisMonthCompleted",attrs:{"stroke-width":_vm.strokeWidth,"fill":"transparent","cx":_vm.cx,"cy":_vm.cy}}):_vm._e(),((_vm.completedThisMonth > _vm.totalBooked && _vm.totalBooked > _vm.completedLastMonth) ||
        (_vm.completedThisMonth > _vm.completedLastMonth && _vm.completedLastMonth > _vm.totalBooked) ||
        (_vm.completedLastMonth > _vm.completedThisMonth && _vm.totalBooked < _vm.completedThisMonth) ||
        (_vm.totalBooked < _vm.completedThisMonth && _vm.completedThisMonth === _vm.completedLastMonth) ||
        (_vm.completedThisMonth === _vm.totalBooked && _vm.totalBooked > _vm.completedLastMonth) ||
        (_vm.completedLastMonth === _vm.completedThisMonth && _vm.completedLastMonth === _vm.totalBooked) ||
        (_vm.completedLastMonth === 0 && _vm.completedThisMonth === 0 && _vm.totalBooked === 0) ||
        (_vm.completedThisMonth > _vm.totalBooked && _vm.totalBooked === _vm.completedLastMonth) ||
        (_vm.completedThisMonth === _vm.totalBooked && _vm.totalBooked < _vm.completedLastMonth))?_c('circle',{ref:"thisMonthCircle",staticClass:"DashboardMonthPerformanceChart__Circle DashboardMonthPerformanceChart__LastMonthCompleted",attrs:{"stroke-width":_vm.strokeWidth,"fill":"transparent","cx":_vm.cx,"cy":_vm.cy}}):_vm._e(),((_vm.completedThisMonth > _vm.totalBooked && _vm.totalBooked > _vm.completedLastMonth) ||
        (_vm.totalBooked > _vm.completedThisMonth && _vm.completedThisMonth > _vm.completedLastMonth) ||
        (_vm.completedLastMonth > _vm.completedThisMonth && _vm.totalBooked < _vm.completedThisMonth) ||
        (_vm.completedThisMonth < _vm.completedLastMonth && _vm.completedLastMonth < _vm.totalBooked) ||
        (_vm.completedLastMonth === _vm.completedThisMonth && _vm.completedLastMonth === _vm.totalBooked) ||
        (_vm.completedLastMonth === 0 && _vm.completedThisMonth === 0 && _vm.totalBooked === 0) ||
        (_vm.completedThisMonth === _vm.completedLastMonth && _vm.totalBooked > _vm.completedLastMonth) ||
        (_vm.completedThisMonth === _vm.totalBooked && _vm.totalBooked < _vm.completedLastMonth))?_c('circle',{ref:"totalBooked",staticClass:"DashboardMonthPerformanceChart__Circle DashboardMonthPerformanceChart__ThisMonthBooked",attrs:{"stroke-width":_vm.strokeWidth,"fill":"transparent","cx":_vm.cx,"cy":_vm.cy}}):_vm._e(),((_vm.completedThisMonth > _vm.totalBooked && _vm.totalBooked > _vm.completedLastMonth) ||
        (_vm.completedThisMonth > _vm.completedLastMonth && _vm.completedLastMonth > _vm.totalBooked) ||
        (_vm.completedThisMonth < _vm.totalBooked && _vm.totalBooked < _vm.completedLastMonth) ||
        (_vm.completedThisMonth < _vm.completedLastMonth && _vm.completedLastMonth < _vm.totalBooked) ||
        (_vm.totalBooked > _vm.completedThisMonth && _vm.totalBooked === _vm.completedLastMonth) ||
        (_vm.completedThisMonth > _vm.totalBooked && _vm.totalBooked === _vm.completedLastMonth) ||
        (_vm.completedThisMonth === _vm.completedLastMonth && _vm.totalBooked > _vm.completedLastMonth))?_c('circle',{ref:"lastMonthCircle",staticClass:"DashboardMonthPerformanceChart__Circle DashboardMonthPerformanceChart__ThisMonthCompleted",attrs:{"stroke-width":_vm.strokeWidth,"fill":"transparent","cx":_vm.cx,"cy":_vm.cy}}):_vm._e(),((_vm.completedThisMonth > _vm.completedLastMonth && _vm.completedLastMonth > _vm.totalBooked) ||
        (_vm.completedThisMonth < _vm.totalBooked && _vm.totalBooked < _vm.completedLastMonth) ||
        (_vm.completedThisMonth === _vm.totalBooked && _vm.totalBooked > _vm.completedLastMonth) ||
        (_vm.totalBooked < _vm.completedThisMonth && _vm.completedThisMonth === _vm.completedLastMonth) ||
        (_vm.totalBooked > _vm.completedThisMonth && _vm.totalBooked === _vm.completedLastMonth) ||
        (_vm.completedThisMonth > _vm.totalBooked && _vm.totalBooked === _vm.completedLastMonth))?_c('circle',{ref:"totalBooked",staticClass:"DashboardMonthPerformanceChart__Circle DashboardMonthPerformanceChart__ThisMonthBooked",attrs:{"stroke-width":_vm.strokeWidth,"fill":"transparent","cx":_vm.cx,"cy":_vm.cy}}):_vm._e(),((_vm.totalBooked > _vm.completedThisMonth && _vm.completedThisMonth > _vm.completedLastMonth) ||
        (_vm.completedThisMonth < _vm.totalBooked && _vm.totalBooked < _vm.completedLastMonth) ||
        (_vm.completedThisMonth < _vm.completedLastMonth && _vm.completedLastMonth < _vm.totalBooked) ||
        (_vm.totalBooked > _vm.completedThisMonth && _vm.totalBooked === _vm.completedLastMonth) ||
        (_vm.completedThisMonth === _vm.completedLastMonth && _vm.totalBooked > _vm.completedLastMonth))?_c('circle',{ref:"thisMonthCircle",staticClass:"DashboardMonthPerformanceChart__Circle DashboardMonthPerformanceChart__LastMonthCompleted",attrs:{"stroke-width":_vm.strokeWidth,"fill":"transparent","cx":_vm.cx,"cy":_vm.cy}}):_vm._e(),((_vm.totalBooked > _vm.completedThisMonth && _vm.completedThisMonth > _vm.completedLastMonth) ||
        (_vm.completedThisMonth === _vm.totalBooked && _vm.totalBooked > _vm.completedLastMonth))?_c('circle',{ref:"lastMonthCircle",staticClass:"DashboardMonthPerformanceChart__Circle DashboardMonthPerformanceChart__ThisMonthCompleted",attrs:{"stroke-width":_vm.strokeWidth,"fill":"transparent","cx":_vm.cx,"cy":_vm.cy}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }