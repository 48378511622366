

import { Component, Vue } from 'vue-property-decorator';
import calendar from '@/modules/Calendar';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import Time from './Time/index.vue';
import FilterContainer from './FilterContainer/index.vue';
import ViewControls from './ViewControls/index.vue';
import MobileCalendarControls from './mobile.vue';

@Component({
    components: {
        Time,
        FilterContainer,
        ViewControls,
        MobileCalendarControls,
    },
})
export default class CalendarControls extends Vue {
    async created() {
        await calendar.loadEvents();
    }

    @Get(system) private screenType!: string;
}
