const exploreEnrollee = `type ExploreEnrollee {
    enrollee: Enrollee!,
    breadcrumbs: [Enrollee],
}`;

const exploreEnrolleeQuery = `
exploreEnrollee(id: String): ExploreEnrollee
`;

export {
    exploreEnrollee, exploreEnrolleeQuery,
};
