
import { Vue, Component, Prop } from 'vue-property-decorator';
import requirementRisingStars from '@/modules/RisingStars/requirementRisingStars';
import { Get } from '@/utils/vuex-module-mutators';
import { LeaderLegsType, RankType } from '@/api/graphQL/graphNodes/types';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import AmbassadorLegsDetailsItem from './item.vue';

@Component({
    components: {
        AmbassadorLegsDetailsItem,
    },
})
export default class RankRequirementAmbassadorLegDetails extends Vue {
    @Get(requirementRisingStars, 'activeLegDetailsDialog') private id!: string;

    @Get(requirementRisingStars) private loading!: boolean;

    @Get(requirementRisingStars) private legs!: LeaderLegsType[];

    @Get(requirementRisingStars) private loadingInBackground!: boolean;

    @Get(requirementRisingStars) private offset!: number;

    @Get(requirementRisingStars) private limit!: number;

    @Get(requirementRisingStars) private legsCount!: number;

    @Prop() private ranks!: RankType[];

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        await requirementRisingStars.getRisingStars({ id: this.id, offset, loadPage: true });
        scrollToTop('#app > div.vue-portal-target > div > div > div > div.RankRequirementAmbassadorLegDetails__Container');
    }

    async created() {
        await requirementRisingStars.getRisingStars({ id: this.id });
    }
}
