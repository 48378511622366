
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { SelectOptionsType } from '@/components/select/types';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UISelects extends Vue {
    @Get(componentsSandbox) searchSelectOptions!: SelectOptionsType[];

    @Get(componentsSandbox) selectSelected!: string;

    handleSelect(val: string) {
        componentsSandbox.setSelectSelected(val);
    }
}
