
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import componentsControls from '@/modules/ComponentsControls';

@Component
export default class DrawerFab extends Vue {
    @Get(componentsControls) private drawerOpened!: boolean;

    @Prop({ default: false }) private disabled!: boolean;

    get fabClasses() {
        return [
            'DrawerFab Ripple',
            'DrawerFab--contained',
            'md-elevation-8',
            this.drawerOpened ? 'DrawerFab--active' : '',
        ];
    }

    handleClick() {
        componentsControls.toggleDrawerOpen();
    }
}
