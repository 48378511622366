
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { INVALID_YEAR_FORMAT } from './messages';

@Component
export default class DatePickerYearComponent extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ required: true }) private value!: string;

    private innerVal = this.value;

    private error = '';

    private yearFullRegex = /(^[1-2]$)|(^(19|20)[0-9]?[0-9]?$)$/;

    private yearRegex = /(?:(?:19|20)[0-9]{2})$/;

    handleYearUpdate(val: string) {
        if (this.yearRegex.exec(val) !== null) {
            this.$emit('onYearUpdate', val);
        } else {
            this.setVal(val);
        }
    }

    validYearFormat(val: string) {
        if (this.yearFullRegex.exec(val) === null && val !== '') {
            this.error = INVALID_YEAR_FORMAT;
        } else {
            this.error = '';
        }
    }

    elementOnBlur(el: any) {
        if (this.yearRegex.exec(this.innerVal) === null) {
            this.setVal(this.value);
        }
        this.$emit('onBlur', el);
    }

    elementOnFocus(el: any) {
        if (!this.disabled) {
            this.$emit('onFocus', el);
            this.$emit('onClick', el);
        }
    }

    @Watch('value')
    setVal(val: string) {
        this.innerVal = val;
        this.validYearFormat(val);
    }
}
