import { EVENT_INVITATION_TYPE } from '@/modules/Event/constants';
import { enrollee } from './enrollee';
import getToken from '../services/tokenResolver';
import { ResolverInvitationType } from '../types';

const eventInvitations: ResolverInvitationType[] = [
    {
        id: '5',
        address: null,
        attending: null,
        email: '',
        invitedAt: '',
        invitedBy: enrollee(getToken()),
        enrollee: '1',
        name: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        respondedAt: null,
        type: EVENT_INVITATION_TYPE.guest,
    },
    {
        id: '6',
        address: null,
        attending: null,
        email: '',
        invitedAt: '',
        invitedBy: enrollee(getToken()),
        enrollee: '1',
        name: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        respondedAt: null,
        type: EVENT_INVITATION_TYPE.hostess,
    },
    {
        id: '7',
        address: null,
        attending: null,
        email: '',
        invitedAt: '',
        invitedBy: enrollee(getToken()),
        enrollee: '1',
        name: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        respondedAt: null,
        type: EVENT_INVITATION_TYPE.hostess,
    },
];

const eventInvitation: (id: string) => ResolverInvitationType = (id) => eventInvitations
    .find((invitation: ResolverInvitationType) => invitation.id === id)!;

export { eventInvitation };
