import setings from '@/modules/Settings';
import LocalStorageManager from '@/utils/local-storage-manager';

const STORAGE_KEY = 'user-settings';

class SettingsLoader {
    load() {
        const stored = LocalStorageManager.getStorage(STORAGE_KEY);
        if (stored) {
            setings.setSettings(JSON.parse(stored));
        }
    }

    save() {
        LocalStorageManager.setStorage(STORAGE_KEY, JSON.stringify(setings.userSettings));
    }
}

export default new SettingsLoader();
