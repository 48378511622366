
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RegistrationStepsOptionsType } from '@/projectComponents/registrationSteps/types';

@Component
export default class RegistrationStepItem extends Vue {
    @Prop() private step!: RegistrationStepsOptionsType;

    @Prop() private index!: number;

    @Prop() private current!: number;

    get activeStep() {
        const { index, current } = this;

        return index === current;
    }

    get connectorClasses() {
        const { index, current } = this;
        return [
            'RegistrationStepItem__Connector',
            index < current ? 'RegistrationStepItem__Connector--active' : '',
        ];
    }

    get bubbleClasses() {
        const { index, current } = this;

        return [
            'RegistrationStepItem__BubbleContainer',
            index <= current ? 'RegistrationStepItem__BubbleContainer--active' : '',
        ];
    }
}
