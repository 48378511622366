/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { ErrorType, ObjectProcessor, CustomAction as Action } from '@plumtreesystems/utils';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { DriverLicenseType, PassportType } from '@/api/graphQL/graphNodes/types';
import componentsControls from '@/modules/ComponentsControls';
import { REGISTERED_MESSAGE } from '@/utils/messages/formValidation';
import { REGISTER_FLAGGED_DOCUMENT_TYPE } from './constants';
import { FORM_ERROR_TOOLTIP } from '../constants';
import { initialRegistrationDriverLicenseData, initialRegistrationPassportData } from './defaults';
import RegisterRepository from './services/registerRepository';
import registrationPassportFormValidation from './services/registrationPassportFormValidation';
import registrationDriverLicenseFormValidation from './services/registrationDriverLicenseFormValidation';
import { ObjectPropertyType } from '../types';

@Module({
    namespaced: true, dynamic: true, store, name: 'flaggedRegistration',
})
@AutoMutations
export class FlaggedRegistration extends VuexModule {
    private type: string = REGISTER_FLAGGED_DOCUMENT_TYPE.passport;

    private passportData: PassportType = initialRegistrationPassportData();

    private driverLicenseData: DriverLicenseType =
     initialRegistrationDriverLicenseData();

    private formPassportErrors: ErrorType = {};

    private formDriverLicenseErrors: ErrorType = {};

    private loading: boolean = false;

    private displayTooltip: boolean = false;

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setType(val: string) {
        this.type = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setPassportFormData(data: PassportType = initialRegistrationPassportData()) {
        this.passportData = { ...data };
    }

    @Mutation
    public setDriverLicenseFormData(data: DriverLicenseType
    = initialRegistrationDriverLicenseData()) {
        this.driverLicenseData = { ...data };
    }

    @Mutation
    public setFormPassportErrors(payload: ObjectPropertyType) {
        this.formPassportErrors = ObjectProcessor.setPropertyByValue(
            payload.key, payload.val, this.formPassportErrors,
        );
    }

    @Mutation
    public setFormDriverLicenseErrors(payload: ObjectPropertyType) {
        this.formDriverLicenseErrors = ObjectProcessor.setPropertyByValue(
            payload.key, payload.val, this.formDriverLicenseErrors,
        );
    }

    @Mutation
    public clearPassportFormErrors() {
        this.formPassportErrors = {};
    }

    @Mutation
    public clearDriverLicenseFormErrors() {
        this.formDriverLicenseErrors = {};
    }

    @Mutation
    public removeFormPassportErrors(key) {
        const { formPassportErrors } = this;
        delete formPassportErrors[key];
        this.formPassportErrors = { ...formPassportErrors };
    }

    @Mutation
    public removeFormDriverLicenseErrors(key) {
        const { formDriverLicenseErrors } = this;
        delete formDriverLicenseErrors[key];
        this.formDriverLicenseErrors = { ...formDriverLicenseErrors };
    }

    @Mutation
    public setPassportFormError(payload: ObjectPropertyType) {
        this.formPassportErrors = ObjectProcessor
            .setPropertyByValue(payload.key, payload.val, this.formPassportErrors);
    }

    @Mutation
    public removePassportFormError(key) {
        const { formPassportErrors } = this;
        delete formPassportErrors[key];
        this.formPassportErrors = { ...formPassportErrors };
    }

    @Mutation
    public setDriverLicenseFormError(payload: ObjectPropertyType) {
        this.formDriverLicenseErrors = ObjectProcessor.setPropertyByValue(
            payload.key, payload.val, this.formDriverLicenseErrors,
        );
    }

    @Mutation
    public removeDriverLicenseFormError(key) {
        const { formDriverLicenseErrors } = this;
        delete formDriverLicenseErrors[key];
        this.formDriverLicenseErrors = { ...formDriverLicenseErrors };
    }

    @Action()
    public validatePassportForm() {
        this.clearPassportFormErrors();
        const formErrors = registrationPassportFormValidation(this.passportData);
        formErrors.forEach((error) => this.setPassportFormError(error));
    }

    @Action()
    public validateDriverLicenseForm() {
        this.clearDriverLicenseFormErrors();
        const formErrors = registrationDriverLicenseFormValidation(this.driverLicenseData);
        formErrors.forEach((error) => this.setDriverLicenseFormError(error));
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public async submitDocument(token: string) {
        try {
            this.setLoading(true);
            this.clearPassportFormErrors();
            this.clearDriverLicenseFormErrors();

            if (this.type === REGISTER_FLAGGED_DOCUMENT_TYPE.passport) {
                this.validatePassportForm();

                if (Object.keys(this.formPassportErrors).length === 0) {
                    await RegisterRepository
                        .passportSubmit({ registerPassport: this.passportData }, token);
                    componentsControls.showSuccessMessage({ message: REGISTERED_MESSAGE });
                } else {
                    this.displayFormErrorsTooltip();
                }
            } else {
                this.validateDriverLicenseForm();

                if (Object.keys(this.formDriverLicenseErrors).length === 0) {
                    await RegisterRepository
                        .driverLicenseSubmit(
                            { registerDriverLicense: this.driverLicenseData }, token,
                        );
                    componentsControls.showSuccessMessage({ message: REGISTERED_MESSAGE });
                } else {
                    this.displayFormErrorsTooltip();
                }
            }
        } catch (e) {
            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(FlaggedRegistration);
