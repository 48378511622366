const bgImage = (screenType: string): string => {
    if (screenType === 'mobile') {
        return 'iPhone X.png';
    } if (screenType === 'tablet') {
        return 'iPad Pro.png';
    }
    return 'Full Screen.png';
};

export default bgImage;
