

import { Component, Vue } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import calendar from '@/modules/Calendar';
import { CALENDAR_TIME_FRAME_OPTIONS } from '@/modules/Calendar/constants';
import TimeControls from './Controls/index.vue';

@Component({
    components: {
        TimeControls,
    },
})
export default class Time extends Vue {
    @Get(calendar, 'calendarDate') date!: string;

    @Get(calendar, 'calendarTimeFrame') calendarTimeFrame!: string;

    get isDailyView() {
        return this.calendarTimeFrame === CALENDAR_TIME_FRAME_OPTIONS.day;
    }

    get selectedMonthDate() {
        return calendar.getSelectedMonthDate;
    }

    get selectedDayDate() {
        return calendar.getSelectedDayDisplayDate;
    }
}
