
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import flaggedRegistration from '@/modules/Register/flaggedRegistration';
import { PassportType } from '@/api/graphQL/graphNodes/types';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import env from '@/environment';

@Component({
    components: {
        FormFieldTooltip,
    },
})
export default class RegisterVerificationPassport extends Vue {
    @Get(flaggedRegistration, 'passportData') private formData!: PassportType;

    @Get(flaggedRegistration, 'formPassportErrors') private formErrors!: ErrorType;

    get baseUrl() {
        return env.BASE_URL;
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.formData);

        flaggedRegistration.removePassportFormError(key);
        flaggedRegistration.setPassportFormData(res);
    }
}
