
import { Vue, Component } from 'vue-property-decorator';
import { Sync, Get } from '@/utils/vuex-module-mutators';
import eventInvitationModule from '@/modules/Event/EventInvitation';
import { ErrorType } from '@plumtreesystems/utils';
import EmailInvite from '@/projectComponents/emailInvite/index.vue';
import { EventInviteType } from '@/modules/Event/EventInvitation/types';
import componentsControls from '@/modules/ComponentsControls';
import { EVENT_INVITATION_SENT } from '@/modules/Event/EventInvitation/messages';

@Component({
    components: { EmailInvite },
})
export default class EventInvite extends Vue {
    @Sync(eventInvitationModule, 'inviteFormData') private formData!: EventInviteType;

    @Get(eventInvitationModule, 'inviteFormErrors') private formErrors!: ErrorType;

    @Get(eventInvitationModule) private loading!: boolean;

    @Get(eventInvitationModule) private inviteSent!: boolean;

    @Get(eventInvitationModule) private displayTooltip!: boolean;

    @Get(eventInvitationModule) private tooltipError!: string;

    mounted() {
        this.cleanForm();
    }

    beforeDestroy() {
        eventInvitationModule.setDisplayTooltip(false);
    }

    async handleInviteSend() {
        try {
            const { event } = this.$route.query;
            await eventInvitationModule.handleInviteSend(event as string);
            componentsControls.showSuccessMessage({ message: EVENT_INVITATION_SENT });
        } catch (e) {
            console.error(e);
        }
    }

    cleanForm() {
        eventInvitationModule.clearInviteFormErrors();
        eventInvitationModule.clearInviteFormData();
    }

    inviteAnotherOne() {
        this.cleanForm();
        eventInvitationModule.setInviteSent(false);
    }
}
