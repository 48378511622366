const resizeMe = (img, maxWidth: number = 50, maxHeight: number = 50) => {
    const canvas = document.createElement('canvas');

    let { width } = img;
    let { height } = img;

    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
        // height *= maxWidth / width;
            height = Math.round(height *= maxWidth / width);
            width = maxWidth;
        }
    } else if (height > maxHeight) {
        // width *= maxHeight / height;
        width = Math.round(width *= maxHeight / height);
        height = maxHeight;
    }

    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx!.drawImage(img, 0, 0, width, height);

    const res = canvas.toDataURL('image/jpeg', 1); // get the data from canvas as 70% JPG (can be also PNG, etc.)
    canvas.remove();

    return res;
};

const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','); const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]); let n = bstr.length; const
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

const processFile = async (file: File): Promise<string> => {
    if (!(/image/i).test(file.type)) {
        // handle if not image
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    const resultImage = new Promise<string>((resolve) => {
        reader.onload = (event) => {
            // @ts-ignore
            const blob = new Blob([event.target?.result]);
            window.URL = window.URL || window.webkitURL;
            const blobURL = window.URL.createObjectURL(blob);

            const image = new Image();
            image.src = blobURL;
            const resizeImage = new Promise<string>((res) => {
                image.onload = async () => {
                    const resized = resizeMe(image, 300, 300);
                    res(resized);
                };
            });

            resizeImage.then((resizeRes) => {
                resolve(resizeRes);
            });
        };
    });

    return resultImage.then((imgRes) => imgRes);
};

const resizeFile = async (file: string): Promise<string> => {
    const image = new Image();
    image.src = file;
    const resizeImage = new Promise<string>((res) => {
        image.onload = async () => {
            const resized = resizeMe(image);
            res(resized);
        };
    });

    return resizeImage.then((imgRes) => imgRes);
};

export default processFile;

export { dataURLtoFile, resizeFile };
