const me = `type Me {
    userName: String!,
    roles: [String],
}`;

const meQuery = `
    me: Me
`;

export {
    me, meQuery,
};
