// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { ReportType, RunsType } from './types';

export default class GetReportsQuery extends
    AbstractQueryResource<GetReportsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($runId: String) {
            profile {
                participatedRuns {
                    id,
                    label,
                    from,
                    to
                },
                reports(runId: $runId) {
                    fileName,
                    downloadUrl
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getReports';
    }
}

export type GetReportsResultType = {
    profile: {
        participatedRuns: RunsType[],
        reports: ReportType[],
    }
}

export type GetReportsParamsType = {
    runId: string|null;
}
