
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import dashboard from '@/modules/Dashboard';
import Chart from './Chart/index.vue';
import ChartSales from './ChartSales/index.vue';
import EventsBooked from './EventsBooked/index.vue';
import EventsCompleted from './EventsCompleted/index.vue';

@Component({
    components: {
        Chart,
        ChartSales,
        EventsBooked,
        EventsCompleted,
    },
})
export default class MonthPerformance extends Vue {
    @Get(system) private screenType!: string;

    @Get(dashboard, 'data.performanceData.bookedEvents') private bookedEvents!: number;

    @Get(dashboard, 'data.performanceData.finishedEvents') private finishedEvents!: number;

    @Get(dashboard, 'data.performanceData.lastMonthFinishedEvents') private lastMonthFinishedEvents!: number;
}
