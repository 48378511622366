
import { Component, Prop, Vue } from 'vue-property-decorator';
import dateManager from '@/utils/time';
import { timezoneOptions } from './timezoneOptions';

@Component
export default class TimezoneSelect extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ required: true }) private value!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: '' }) private helperText!: string;

    @Prop({ default: '' }) private error!: string;

    @Prop({ default: '' }) private label!: string;

    private matchValueError: boolean = false;

    private containerClass: string[] = [];

    private open: boolean = false;

    private clickOutsideEvent: Function = () => {};

    private openingElement: any = null;

    get elementClasses() {
        return [
            'TimezoneSelect',
            ...this.containerClass,
            { 'TimezoneSelect--error': this.error !== '' || this.matchValueError },
            { 'TimezoneSelect--disabled': this.disabled },
        ];
    }

    get options() {
        return timezoneOptions;
    }

    addToContainerClass(items: string[]) {
        const combinedObject = new Set([...this.containerClass, ...items]);
        this.containerClass = [...combinedObject];
    }

    removeFromContainerClass(items: string[]) {
        this.containerClass = this.containerClass.filter((classItem) => !items.includes(classItem));
    }

    setActive(active: boolean, pulled: boolean = false) {
        const el: HTMLInputElement = this.$refs.selectInput as HTMLInputElement;

        if (active) {
            this.addToContainerClass(['TimezoneSelect--isPulled']);
        } else if (pulled) {
            this.addToContainerClass(['TimezoneSelect--isFilled']);
        } else if (el.value === '') {
            this.removeFromContainerClass(['TimezoneSelect--isPulled', 'TimezoneSelect--isFilled']);
        } else {
            this.addToContainerClass(['TimezoneSelect--isFilled']);
        }
    }

    openSelect(): void {
        if (!this.disabled) {
            if (this.open) {
                this.closeSelect();
                return;
            }

            this.setOutsideClickListener();
            this.elementOnFocus();
            this.open = true;
        }
    }

    closeSelect(selected: boolean = false) {
        this.elementOnBlur(selected);
        this.open = false;
    }

    setOutsideClickListener(): void {
        const element = this.$refs.selectInputContainer;
        if (this.openingElement === element) {
            return;
        }

        this.openingElement = element;

        this.clickOutsideEvent = (event: Event) => {
            const selectInputEl: HTMLElement = this.$refs.selectInputContainer as HTMLElement;
            const selectedEventNode: Node = event.target as Node;

            if (!(
                selectInputEl === event.target
                || selectInputEl.contains(selectedEventNode)
            )) {
                this.closeFormDataSelectContainer();
            }
        };

        document.addEventListener('click', this.clickOutsideEvent as EventListener);
    }

    closeFormDataSelectContainer(selected: boolean = false): void {
        this.closeSelect(selected);
        this.openingElement = null;
        document.removeEventListener('click', this.clickOutsideEvent as EventListener);
    }

    handleUpdate(val: string) {
        const time = dateManager.getDateTimeWithoutTimezone(this.value);
        const result = `${time}${val}`;
        this.$emit('input', result);
        this.closeFormDataSelectContainer(true);
    }

    findSelectedOption() {
        const resVal = this.handleValue();
        const result = this.options.find((item) => item.value === resVal);
        return result || null;
    }

    get displayValue() {
        if (this.value !== '') {
            const selectedOption = this.findSelectedOption();
            if (selectedOption) {
                this.matchValueError = false;

                return selectedOption.name;
            }

            this.matchValueError = true;
        }

        return '';
    }

    handleValue() {
        const checkedVal = dateManager.getTimeZoneRegex(this.value);

        if (checkedVal) {
            return checkedVal[0].replace(':', '');
        }

        return '';
    }

    created() {
        if (this.value !== '') {
            this.addToContainerClass(['TimezoneSelect--isFilled', 'TimezoneSelect--isPulled']);
        }
    }

    beforeUpdate() {
        if (this.value === '') {
            this.removeFromContainerClass(['TimezoneSelect--isPulled', 'TimezoneSelect--isFilled']);
        } else {
            const selectedOption = this.findSelectedOption();

            if (selectedOption) {
                this.addToContainerClass(['TimezoneSelect--isPulled', 'TimezoneSelect--isFilled']);
            } else {
                this.removeFromContainerClass(['TimezoneSelect--isPulled', 'TimezoneSelect--isFilled']);
            }
        }
    }

    elementOnBlur(selected: boolean) {
        this.setActive(false, selected);
        this.removeFromContainerClass(['TimezoneSelect--focused']);
    }

    elementOnFocus() {
        this.setActive(true);
        this.addToContainerClass(['TimezoneSelect--focused']);
    }
}
