const assetCategories = `type AssetCategory {
    id: String
    name: String!
    priority: Int!
    icon: Asset
}`;

const assetCategoriesQuery = `
    assetCategories(type: String): [AssetCategory]
`;
export {
    assetCategories, assetCategoriesQuery,
};
