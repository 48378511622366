const rank = `type Rank {
    id: String!
    power: Int!,
    label: String!,
    requirements: [RankRequirements],
    titleRequirements: [RankRequirements]
}`;

const rankQuery = `
    ranks: [Rank]
`;

export {
    rank, rankQuery,
};
