
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { mapSettings } from '@/components/map/mapSettings';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { MapMarkerType } from '@/components/map/types';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIMap extends Vue {
    @Get(componentsSandbox) googleMapMarkers!: MapMarkerType[];

    private mapSettings = mapSettings;
}
