import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import store from '@/store';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { GetHostsParamsType } from '@/api/graphQL/graphNodes/GetHostsQuery';
import { MyHostsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import EventRepository from '../Events/services/eventRepository';

export const EVENT_HOST_TYPE = {
    undefined: -1,
    me: 0,
    known: 1,
    new: 2,
};

@Module({
    namespaced: true, dynamic: true, store, name: 'eventHostesses',
})
@AutoMutations
export class EventHostesses extends VuexModule {
    private hostesses: MyHostsType[] = [];

    private loading: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setHostesses(val: MyHostsType[]) {
        this.hostesses = val;
    }

    @Action()
    public async getHostesses() {
        try {
            this.setLoading(true);

            const params: GetHostsParamsType = {
                limit: 0,
                offset: 0,
                query: '',
            };

            const data = await EventRepository.getEventHostesses(params);
            this.setHostesses(data.profile.searchHosts);
        } catch (e) {
            ErrorsProcessor.process(e);
        }

        this.setLoading(false);
    }
}

export default getModule(EventHostesses);
