import { DownlineResultProfileType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class UpdateDownlineProfileQuery extends
    AbstractQueryResource<UpdateDownlineProfileResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($id: String!, $profile: DownlineProfileInput) {
            updateDownlineProfile(enrolleeId: $id, profile: $profile) {
                id,
                firstName,
                lastName,
                phoneNumber,
                email,
                address,
                secondAddress,
                birthDate,
                postCode,
                town,
                county,
                country,
                parentId,
                hidden,
                rank {
                    id,
                    label,
                },
                profilePicture {
                    link,
                    context {
                        metric,
                        value
                    }
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'updateDownlineProfile';
    }
}

export type UpdateDownlineProfileResultType = {
    updateDownlineProfile: DownlineResultProfileType;
}
