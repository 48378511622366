
import { Vue, Component } from 'vue-property-decorator';
import componentsControls from '@/modules/ComponentsControls';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIFiles extends Vue {
    get file1() {
        return {
            id: 'uiFile1',
            fileName: 'fileName.ext',
            originalFileName: 'fileName.ext',
            link: 'https://vimeo.com/200821149',
            context: [
                {
                    value: 'external/video',
                    metric: 'Content-Type',
                },
                {
                    metric: 'Embed-Link',
                    value: 'https://player.vimeo.com/video/200821149?app_id=122963',
                },
            ],
        };
    }

    get file2() {
        return {
            id: 'uiFile2',
            fileName: 'fileName2.ext',
            originalFileName: 'fileName2.ext',
            link: 'https://vimeo.com/200821149',
            context: [
                {
                    value: 'external/video',
                    metric: 'Content-Type',
                },
                {
                    metric: 'Embed-Link',
                    value: 'https://player.vimeo.com/video/200821149?app_id=122963',
                },
            ],
        };
    }

    get file3() {
        return {
            id: 'uiFile3',
            fileName: 'not downloadable',
            originalFileName: 'not downloadable',
            link: 'https://vimeo.com/200821149',
            context: [
                {
                    value: 'external/video',
                    metric: 'Content-Type',
                },
                {
                    metric: 'Embed-Link',
                    value: 'https://player.vimeo.com/video/200821149?app_id=122963',
                },
            ],
        };
    }

    onFileRemove(val: string) {
        componentsControls.showSuccessMessage({ message: `${val} file was removed ` });
    }
}
