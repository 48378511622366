
import { Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import Vue from 'vue';
import system from '@/modules/System';

@Component
export default class SkeletonCalendarMonth extends Vue {
    @Get(system) private screenType!: string;
}
