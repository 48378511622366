
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import incomeSummary from '@/modules/IncomeSummary';
import { AssetType, CommissionStatementsType } from '@/api/graphQL/graphNodes/types';
import env from '@/environment';
import IncomeSummaryPreview from '@/views/IncomeSummary/Statements/Preview/index.vue';
import authenticator from '@/modules/Auth/services/authenticator';

@Component({ components: { IncomeSummaryPreview } })
export default class IncomeSummaryStatements extends Vue {
    @Get(incomeSummary) private files!: CommissionStatementsType[];

    @Get(incomeSummary) private previewOpen!: boolean;

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get fileList(): AssetType[] {
        return incomeSummary.fileList;
    }

    get token(): string {
        return authenticator.authTokenFromStorage || '';
    }

    get noFiles(): boolean {
        return this.fileList.length === 0;
    }

    openPreview(id: string): void {
        incomeSummary.setPreviewOpen(true);
        incomeSummary.setPreviewFileId(id);
    }

    generateDownloadLink(file) {
        return `${this.serverUrl}${file.link}?token=${this.token}`;
    }

    handleDialogClose(): void {
        incomeSummary.setPreviewOpen(false);
        incomeSummary.setPreviewFileId('');
    }
}
