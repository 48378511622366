import routesList, { RouteType } from '../routes';

type FlattenedRouteType = RouteType & {
    breadcrumbs: string[];
}

const flattenRoutes: (routes: RouteType[], breadcrumb?: string[])
    => FlattenedRouteType[] = (routes, breadcrumb = []) => {
        let res: FlattenedRouteType[] = [];
        routes.forEach((element) => {
            res.push({
                ...element,
                breadcrumbs: [...breadcrumb, element.name],
            });

            const { children } = element;
            if (children) {
                res = [...res, ...flattenRoutes(children, [...breadcrumb, element.name])];
            }
        });

        return res;
    };

const availableRoute: (name: string) => boolean = (name = '') => {
    const routes = flattenRoutes(routesList());

    const destinationRoute = routes.find((r) => r.name === name);
    const bannedRoute = destinationRoute?.breadcrumbs.find((item) => {
        const route = routes.find((routesItem) => routesItem.name === item);
        return !!route?.disabled;
    });

    return !bannedRoute;
};

export { availableRoute };
