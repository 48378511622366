import auth from '@/modules/Auth/services/authenticator';
import system from '@/modules/System';

const init = async () => {
    await auth.initAuth();

    window.addEventListener('online', () => system.handleConnectionReestablish());
    window.addEventListener('offline', () => system.handleNetworkError());
};

export default init;
