const STORAGE_KEY = 'auth-token';

class AuthLocalStorageManager {
    setAuthToken(authToken: string) {
        localStorage.setItem(STORAGE_KEY, authToken);
    }

    removeToken() {
        localStorage.removeItem(STORAGE_KEY);
    }

    getAuthToken() {
        return localStorage.getItem(STORAGE_KEY);
    }
}

export default new AuthLocalStorageManager();
