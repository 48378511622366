
import { Vue, Component, Prop } from 'vue-property-decorator';
import { FileType } from '@/components/fileArea/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

@Component
export default class UploadDialogContent extends Vue {
    @Prop({ required: true }) private profilePicUpload!: FileType|null;

    @Prop({ required: true }) private image!: string;

    onFileAdd(files: FileType[]) {
        this.$emit('onFileAdd', files);
    }
}
