
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';

@Component
export default class DatePickerTimeComponent extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ required: true }) private value!: string;

    private timeRegex = /(^[0-9]$)|(^([0-1][0-9]|2[0-3]|[0-9])?:?([0-9]|[0-5][0-9])?$)/;

    private autocompleteRegex = /^([0-1][0-9]|2[0-3])$/;

    private autocompleteMinuteRegex = /^([0-1][0-9][0-5]|2[0-3][0-5])$/;

    private autocompletedRegex = /^:[0-5][0-9]|[0-9]:[0-9]$/;

    private completeVal = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;

    private innerVal = this.value;

    handleTimeUpdate(val: string) {
        if (this.completeVal.exec(val) !== null) {
            this.$emit('onTimeUpdate', val);
        } else if (this.timeRegex.exec(val) !== null || val === '') {
            if (this.autocompleteRegex.exec(val) && !this.autocompletedRegex.exec(this.innerVal)) {
                this.setVal(`${val}:`);
            } else if (this.autocompleteMinuteRegex.exec(val)) {
                this.setVal(`${val.slice(0, 2)}:${val.split('')[2]}`);
            } else {
                this.setVal(val);
            }
        } else {
            const timeInput = this.$refs.timeInput as HTMLInputElement;
            timeInput.value = this.innerVal;
        }
    }

    elementOnBlur(el: any) {
        if (this.completeVal.exec(this.innerVal) === null) {
            this.setVal(this.value);
        }

        this.$emit('onBlur', el);
    }

    elementOnFocus(el: any) {
        if (!this.disabled) {
            this.$emit('onFocus', el);
        }
    }

    @Watch('value')
    setVal(val: string) {
        this.innerVal = val;
    }
}
