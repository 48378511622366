
import { Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import Vue from 'vue';
import calendar from '@/modules/Calendar';
import system from '@/modules/System';
import Week from '../Week/index.vue';
import WeekDays from '../WeekDays/index.vue';

@Component({
    components: {
        Week,
        WeekDays,
    },
})
export default class WeekView extends Vue {
    @Get(calendar) private calendarTimeFrame!: string;

    @Get(system) private screenType!: string;

    get week(): string[] {
        return [...calendar.getTimeFrameDayDates];
    }
}
