// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetEventConfirmationQuery extends
    AbstractQueryResource<GetEventConfirmationResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String!) {
            event(id: $id) {
                confirmed,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getEventConfirmation';
    }
}

export type GetEventConfirmationResultType = {
    event: {
        confirmed: boolean;
    };
}

export type GetEventConfirmationParamsType = {
    id: string;
}
