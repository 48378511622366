
import { Vue, Component } from 'vue-property-decorator';
import { ActionMenuOptionType } from '@/components/actionMenu/types';
import { Get } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIActionMenus extends Vue {
    @Get(componentsSandbox) actionMenuOptions!: ActionMenuOptionType[];

    @Get(componentsSandbox) actionMenuOptionsWithIcons!: ActionMenuOptionType[];

    @Get(componentsSandbox) actionMenuOptionsWithDisabled!: ActionMenuOptionType[];

    @Get(componentsSandbox) actionMenuSelection!: string;

    onOptionSelect(val: string) {
        componentsSandbox.setActionMenuSelection(val);
    }
}
