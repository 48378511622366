
import { Get } from '@/utils/vuex-module-mutators';
import { Vue, Component } from 'vue-property-decorator';
import eventView from '@/modules/Event/EventView';
import { InvitationToEventEnrolleeType } from '@/api/graphQL/graphNodes/types';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class EventGuestDetails extends Vue {
    @Get(eventView) private displayCustomer!: InvitationToEventEnrolleeType;

    @Get(eventView) private displayCustomerDetails!: boolean;

    handleDialogClose() {
        eventView.setDisplayCustomer();
        eventView.setDisplayCustomerDetails(false);
    }
}
