import API from '@/api/graphQL';
import { GetEventsParamsType } from '@/api/graphQL/graphNodes/GetEventsQuery';
import { GetEventsStatisticsParamsType } from '@/api/graphQL/graphNodes/GetEventsStatisticsQuery';

export default class EventRepository {
    static getEvents(data: GetEventsParamsType, options: { type: string }) {
        return API
            .getEvents()
            .query(data, {}, options);
    }

    static getEventsStatistics(data: GetEventsStatisticsParamsType) {
        return API
            .getEventsStatistics()
            .query(data);
    }
}
