
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { HydraObjectType } from '@plumtreesystems/utils';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIPaginationHydra extends Vue {
    @Get(componentsSandbox) currentPage!: number;

    @Get(componentsSandbox, 'paginationHydra') pagination!: HydraObjectType;

    pageChange(page: number) {
        componentsSandbox.changeCurrentPage(page);
    }
}
