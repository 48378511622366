

import { Component, Vue, Prop } from 'vue-property-decorator';
import { MapMarkerType } from './types';

@Component({})
export default class GoogleMapMarker extends Vue {
    @Prop({ required: true }) private google!: any;

    @Prop({ required: true }) private map!: any;

    @Prop({ required: true }) private markerData!: MapMarkerType;

    @Prop({ default: '' }) private markedId!: string;

    @Prop({ default: '' }) private listenMutation!: string;

    private currentMarkedId = this.markerData.id;

    private marker: any = null;

    private infowindow: any = null;

    private position = { lat: 0, lng: 0 };

    toggleInfoWindow() {
        const { infowindow } = this;
        if (infowindow !== null) {
            infowindow.open(this.map, this.marker);
        }
    }

    created() {
        this.$store.subscribe((mutation) => {
            const { type, payload } = mutation;

            if (type === this.listenMutation && this.marker) {
                if (payload === this.markerData.id) {
                    this.map.setCenter({ lat: this.position.lat, lng: this.position.lng });
                    this.map.setZoom(12);
                }
            }
        });
    }

    mounted() {
        const { address, position } = this.markerData;

        if (address) {
            const geocoder = new this.google.maps.Geocoder();

            if (geocoder) {
                geocoder.geocode({ address }, (results) => {
                    if (results.length !== 0) {
                        const { lat, lng } = results[0].geometry.location;
                        this.position = { lat: lat(), lng: lng() };
                        this.addInfowindow(this.position);

                        if (this.marker) {
                            this.marker.addListener('click', () => this.toggleInfoWindow());
                        }
                    }
                });
            }
        } else if (position) {
            this.position = position;
            this.addInfowindow(this.position);

            this.marker.addListener('click', () => this.toggleInfoWindow());
        }
    }

    beforeDestroy() {
        if (this.marker) {
            this.marker.setMap(null);
        }
    }

    addInfowindow(position) {
        const { id } = this.markerData;

        this.marker = new this.google.maps.Marker({
            position,
            marker: { id },
            map: this.map,
        });

        const { data } = this.markerData;

        const contentString = `<div><span>${data}</span></div>`;

        this.infowindow = new this.google.maps.InfoWindow({
            content: contentString,
        });
    }
}
