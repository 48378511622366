
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import dateManager from '@/utils/time';
import { Get } from '@/utils/vuex-module-mutators';
import calendar from '@/modules/Calendar';
import events from '@/modules/Calendar/Events';
import { EventType } from '@/api/graphQL/graphNodes/types';
import { CalendarObject, CalendarDayObject, CalendarDisplayDayObject } from '@/modules/Calendar/Events/types';
import Pill from './Pill/index.vue';
import OverfullView from './Overfull/index.vue';

@Component({
    components: {
        Pill,
        OverfullView,
    },
})
export default class CalendarDesktopDay extends Vue {
    @Prop() private id!: string;

    @Prop() private thisDay!: boolean;

    @Prop() private showWeekDay!: boolean;

    @Get(calendar) private calendarDate!: string;

    @Get(calendar) private eventsTypeFilter!: string;

    @Get(events) days!: CalendarObject;

    get containerClasses() {
        return [
            'CalendarDesktopDay__Container',
            this.thisDay ? 'CalendarDesktopDay__CurrentContainer' : '',
        ];
    }

    get dayContainerClasses() {
        return [
            'CalendarDesktopDay__DayNumberElement',
            this.showWeekDay ? 'CalendarDesktopDay__WithWeekDay' : '',
        ];
    }

    get day(): string {
        return dateManager.getDateTime(this.id, 'DD');
    }

    get thisMonth(): boolean {
        return dateManager.getDateTime(this.id, 'MM') === dateManager.getDateTime(this.calendarDate, 'MM');
    }

    get totalEvents(): number {
        const day: CalendarDayObject = this.days[this.id];
        return day.statistics.totalEvents;
    }

    get weekDay(): string {
        return dateManager.getDateTime(this.id, 'dd');
    }

    get dayPills(): CalendarDisplayDayObject {
        const data = events.displayEvents;
        return data[this.id];
    }

    get pills(): (EventType|null)[] {
        return this.dayPills.pills;
    }

    get overfull(): boolean {
        const { overfull } = this.dayPills;
        if (this.dayPills && overfull) {
            return overfull;
        }
        return false;
    }
}
