// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetAmbassadorKitQuery extends
    AbstractQueryResource<GetAmbassadorKitResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                paidForKit,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getAmbassadorKit';
    }
}

export type GetAmbassadorKitResultType = {
    profile: {
        paidForKit: boolean
    };
}
