// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { AmbassadorInvitationType, InvitationToEventType } from './types';

export default class InviteGuestQuery extends
    AbstractQueryResource<InviteGuestResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($eventId: String!, $invitation: GuestInvitationType) {
            inviteGuest(eventId: $eventId, invitation: $invitation) {
                attending
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'inviteGuest';
    }
}

export type InviteGuestResultType = {
    inviteGuest: InvitationToEventType;
}

export type InviteEventGuestParamsType = {
    eventId: string;
    invitation: AmbassadorInvitationType;
}
