// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { RegisterHostessType } from './types';

export default class RegisterCustomerQuery extends
    AbstractQueryResource<any> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($register: HostessRegisterInput) { 
            registerCustomer(register: $register) {
                id
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'registerCustomer';
    }
}

export type RegisterHostessParamsType = {
    register: RegisterHostessType;
}
