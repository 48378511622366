import { EventTotalsType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetEventsStatisticsQuery extends
    AbstractQueryResource<GetEventsStatisticsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($dateFrom: DateTime, $dateTo: DateTime, $type: String!) {
            eventsTotals(dateFrom: $dateFrom, dateTo: $dateTo, type: $type) {
                totalEvents,
                totalSales,
                avgSalesPerEvent,
                avgCustomersPerEvent
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getEventsTotals';
    }
}

export type GetEventsStatisticsResultType = {
    eventsTotals: EventTotalsType;
}

export type GetEventsStatisticsParamsType = {
    dateFrom: string;
    dateTo: string;
    type: string
}
