import { ResolverCustomerOrderItemType, ResolverProductType } from '../types';
import { product } from './products';

const calculateTotal: (price: number, quantity: number) => number = (price, quantity) => {
    const cents: number = Math.floor(price * 100);

    const total: number = cents * quantity;

    return total / 100;
};

const saleProduct: (id: string, quantity: number) =>
    ResolverCustomerOrderItemType = (id, quantity) => {
        const productItem: ResolverProductType = product(id);
        const total = calculateTotal(productItem.price, quantity);

        const res = {
            total,
            id,
            price: productItem.price,
            description: productItem.title,
            quantity,
            cv: productItem.price / 10,
            applicableVat: Math.floor(productItem.price * 20) / 100,
            category: 'product',
            net: 0,
            vat: Math.floor(productItem.price * 20) / 100,
            discounts: 0,
        };

        return res;
    };

export { saleProduct };
