import { AssetCategoryType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetAssetsCategoriesQuery extends
    AbstractQueryResource<GetAssetsCategoriesResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($type: String!) {
            assetCategories (type: $type){
                id,
                name,
                priority,
                icon {
                    link
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getAssetsCategories';
    }
}

export type GetAssetsCategoriesResultType = {
    assetCategories: AssetCategoryType[];
}
export type GetAssetsCategoriesParamsType = {
    type: string,
}
