// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class UpdateTermsAndConditionsQuery extends
    AbstractQueryResource<updateTermsAndConditionsResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()} {
            agreeToTermsAndConditions {
                id
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'updateTermsAndConditions';
    }
}

export type updateTermsAndConditionsResultType = {
    id: string;
}
