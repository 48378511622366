
import {
    Component, Vue, Watch,
} from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import componentsControls from '@/modules/ComponentsControls';

@Component
export default class Drawer extends Vue {
    @Get(componentsControls) private drawerOpened!: boolean;

    get classes() {
        return [
            'Drawer__Navigation',
            this.drawerOpened ? 'Drawer__Navigation--active' : '',
        ];
    }

    @Watch('$route')
    handleRouteChange() {
        if (this.drawerOpened) {
            componentsControls.toggleDrawerOpen();
        }
    }
}
