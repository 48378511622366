
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIInlineEdits extends Vue {
    @Get(componentsSandbox) customInlineEditOpen!: boolean;

    @Get(componentsSandbox) createInlineEditOpen!: boolean;

    @Get(componentsSandbox) editInlineEditOpen!: boolean;

    @Get(componentsSandbox) removeInlineEditOpen!: boolean;

    @Get(componentsSandbox) inlineEditOpen!: boolean;

    @Sync(componentsSandbox) name!: string;

    handleCustomInline() {
        componentsSandbox.toggleCustomInlineEditOpen();
    }

    handleCreateInline() {
        componentsSandbox.toggleCreateInlineEditOpen();
    }

    handleEditInline() {
        componentsSandbox.toggleEditInlineEditOpen();
    }

    handleRemoveInline() {
        componentsSandbox.toggleRemoveInlineEditOpen();
    }

    handleInline() {
        componentsSandbox.toggleInlineEditOpen();
    }
}
