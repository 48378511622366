// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { AmbassadorInvitationType } from './types';

export default class PublicInviteAmbassadorQuery extends
    AbstractQueryResource<AmbassadorInvitationType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($email: String!, $firstName: String!, $lastName: String!) {
            inviteAmbassador(email: $email, firstName: $firstName, lastName: $lastName) {
                email,
                firstName,
                lastName
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'inviteAmbassador';
    }
}

export type PublicInviteAmbassadorResultType = {
    firstName: string;
    lastName: string;
    email: string;
}

export type PublicInviteAmbassadorParamsType = {
    firstName: string;
    lastName: string;
    email: string;
}
