
import { Vue, Component, Prop } from 'vue-property-decorator';
import requirementLeaderLegs from '@/modules/LeaderLegs/requirementLeaderLegs';
import { Get } from '@/utils/vuex-module-mutators';
import { LeaderLegsType, RankType } from '@/api/graphQL/graphNodes/types';
import { scrollToTop } from '@plumtreesystems/utils';
import { PaginationParamsType } from '@/components/pagination/types';
import LeaderLegsDetailsItem from './item.vue';

@Component({
    components: {
        LeaderLegsDetailsItem,
    },
})
export default class RankRequirementLeaderLegDetails extends Vue {
    @Get(requirementLeaderLegs, 'activeLegDetailsDialog') private id!: string;

    @Get(requirementLeaderLegs) private loading!: boolean;

    @Get(requirementLeaderLegs) private legs!: LeaderLegsType[];

    @Get(requirementLeaderLegs) private loadingInBackground!: boolean;

    @Get(requirementLeaderLegs) private offset!: number;

    @Get(requirementLeaderLegs) private limit!: number;

    @Get(requirementLeaderLegs) private legsCount!: number;

    @Prop() private ranks!: RankType[];

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        await requirementLeaderLegs.getLeaderLegs({ id: this.id, offset, loadPage: true });
        scrollToTop('#app > div.vue-portal-target > div > div > div > div.RankRequirementLeaderLegDetails__Container');
    }

    async created() {
        await requirementLeaderLegs.getLeaderLegs({ id: this.id });
    }
}
