import API from '@/api/graphQL';
import { GetReportsParamsType } from '@/api/graphQL/graphNodes/GetReportsQuery';

export default class ReportsRepository {
    static getReports(params: GetReportsParamsType) {
        return API
            .getReports()
            .query(params);
    }
}
