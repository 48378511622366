
import Vue from 'vue';
import Component from 'vue-class-component';
import { Get } from '@/utils/vuex-module-mutators';
import componentsControls from '@/modules/ComponentsControls';
import { SnackbarType } from '@/modules/ComponentsControls/types';

@Component
export default class SnackbarMessages extends Vue {
    @Get(componentsControls) displaySnackbar!: SnackbarType | null;

    messageClasses(type: string) {
        return [
            'SnackbarMessage__Element',
            type === 'success'
                ? 'SnackbarMessage--success' : 'SnackbarMessage--error',
        ];
    }
}
