import { RankType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetRanksQuery extends AbstractQueryResource<GetRanksResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            ranks {
                id,
                label,
                power,
                requirements {
                    metric,
                    value
                },
                titleRequirements {
                    metric,
                    value
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getRanks';
    }
}

export type GetRanksResultType = {
    ranks: RankType[]
}
