
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class VideoViewer extends Vue {
    @Prop({ required: true }) private src!: string;

    get elementClasses() {
        return [
            'pts-layout pts-gutter',
        ];
    }
}
