
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import flaggedRegistration from '@/modules/Register/flaggedRegistration';
import { DriverLicenseType } from '@/api/graphQL/graphNodes/types';

@Component({})
export default class RegisterVerificationDriverLicense extends Vue {
    @Get(flaggedRegistration, 'driverLicenseData') private formData!: DriverLicenseType;

    @Get(flaggedRegistration, 'formDriverLicenseErrors') private formErrors!: ErrorType;

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.formData);

        flaggedRegistration.removeDriverLicenseFormError(key);
        flaggedRegistration.setDriverLicenseFormData(res);
    }
}
