
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tag extends Vue {
    @Prop({ required: true }) private text!: string;

    @Prop({ default: '' }) private thumbnail!: string;

    @Prop({ default: '' }) private actionIcon!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: '' }) private type!: string;

    get elementClasses() {
        return [
            'Tag',
            this.disabled ? 'Tag--disabled' : 'IconRipple',
            { 'Tag--withActionIcon': this.actionIcon },
            { 'Tag--withThumbnailIcon': this.thumbnail },
            { 'Tag--outlined': this.type === 'outlined' },
            'body2',
        ];
    }

    handleClick() {
        if (!this.disabled) {
            this.$emit('click');
        }
    }
}
