import Vue from 'vue';
import Vuex from 'vuex';
import localStoragePlugin from '@/plugins/localStorage';
import StoreType from './types';
import Store from './store';

Vue.use(Vuex);

export default new Store<StoreType>({
    plugins: [
        localStoragePlugin,
    ],
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
});
