
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import helpAndSupportAssets from '@/modules/HelpAndSupport/assets';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import env from '@/environment';
import { scrollToTop } from '@plumtreesystems/utils';
import { PaginationParamsType } from '@/components/pagination/types';
import { assetFileResolver, getType } from '@/projectComponents/fileViewer/service/fileResolvers';
import HelpAndSupportPreview from '../Preview/index.vue';

@Component({
    components: {
        HelpAndSupportPreview,
    },
})
export default class HelpAndSupportHostessAssets extends Vue {
    @Get(helpAndSupportAssets) private files!: AssetType[];

    @Get(helpAndSupportAssets) private offset!: number;

    @Get(helpAndSupportAssets) private limit!: number;

    @Get(helpAndSupportAssets) private itemsTotal!: number;

    @Get(helpAndSupportAssets) private loadingInBackground!: number;

    @Get(helpAndSupportAssets) private previewOpen!: number;

    @Sync(helpAndSupportAssets) private searchText!: string;

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get galleryEmpty(): boolean {
        return !(this.files.length > 0);
    }

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;

        await helpAndSupportAssets.getFiles({ loadPage: true, offset });
        scrollToTop('#app > div.App__Layout > div > div.Scene__Content > div > div:nth-child(2) > div');
    }

    openPreview(id: string): void {
        helpAndSupportAssets.setPreviewOpen(true);
        helpAndSupportAssets.setPreviewFileId(id);
    }

    handleDialogClose(): void {
        helpAndSupportAssets.setPreviewOpen(false);
        helpAndSupportAssets.setPreviewFileId('');
    }

    checkIfDownloadable(file: AssetType): boolean {
        const type = file.context.find((item) => item.metric === 'Content-Type');

        return !(!type || type.value === 'external/video');
    }

    isPreviewAvailable(file: AssetType): boolean {
        const type = getType(file);

        if (!type) {
            return false;
        }

        const previewType = assetFileResolver(type.value);

        return previewType !== '';
    }
}
