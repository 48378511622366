import { FIELD_REQUIRED_MESSAGE } from '@/utils/messages/formValidation';
import { PassportType } from '@/api/graphQL/graphNodes/types';
import { ValidationErrorType } from '../../types';
import { PASSPORT_FORMAT } from '../messages';

const registrationPassportFormValidation:
    (val: PassportType) =>
    ValidationErrorType[] = (val) => {
        const errors: ValidationErrorType[] = [];

        if (!val.passport) {
            errors.push({ key: 'passport', val: FIELD_REQUIRED_MESSAGE });
        }

        if (val.passport && val.passport.length !== 44) {
            errors.push({ key: 'passport', val: PASSPORT_FORMAT });
        }

        return errors;
    };

export default registrationPassportFormValidation;
