
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import calendar from '@/modules/Calendar';
import dateManager from '@/utils/time';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';

@Component
export default class MonthView extends Vue {
    @Get(system) private screenType!: string;

    get weekDaysList(): string[] {
        const res: string[] = [];
        const firstDay = calendar.getCalendarStart;

        for (let i = 0; i < 7; i++) {
            res.push(dateManager.getDateWithOffset(i, firstDay, 'dd'));
        }

        return res;
    }
}
