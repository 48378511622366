import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';

@Module({
    namespaced: true, dynamic: true, store, name: 'impersonationControls',
})
@AutoMutations
export class ImpersonationControls extends VuexModule {
    private loading: boolean = false;

    @Mutation
    setLoading(val: boolean) {
        this.loading = val;
    }
}

export default getModule(ImpersonationControls);
