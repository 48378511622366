
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';

@Component
export default class Fab extends Vue {
    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ required: true }) private icon!: string;

    get fabClasses() {
        return [
            'Fab',
            'Fab--contained',
            'md-elevation-8',
        ];
    }

    handleClick() {
        this.$emit('click');
    }
}
