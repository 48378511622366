
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import SocialButtons from '@/projectComponents/socialButtons/index.vue';
import eventInvitationModule from '@/modules/Event/EventInvitation';
import env from '@/environment';
import { EventViewType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {
        SocialButtons,
    },
})
export default class ShareEventInvitation extends Vue {
    @Get(eventInvitationModule) private eventData!: EventViewType;

    @Get(eventInvitationModule) private showQrCode!: boolean;

    @Prop() private eventInvitationLink!: string;

    handleInvite() {
        eventInvitationModule.toggleQrCode();
    }

    get invitiationUrl() {
        return `${env.VUE_APP_FULL_PATH}${this.eventInvitationLink}`;
    }
}
