/* eslint-disable max-classes-per-file */
import axios from 'axios';
import EventEmitter from 'eventemitter3';

export enum Methods {
    GET,
    GET_ALL,
    PATCH,
    POST,
    DELETE
}

abstract class AbstractSubResource extends EventEmitter {
    private parent?: AbstractSubResource;

    private token?: string | (() => string);

    protected noAuthentication?: boolean;

    public id?: string;

    protected nested: boolean;

    get Id(): string | undefined {
        return this.id;
    }

    constructor(parent?: AbstractSubResource, id?: string, nested = true) {
        super();
        this.parent = parent;
        this.id = id;
        this.nested = nested;
    }

    /**
     * Return relative path to the resource
     */
    protected abstract getPath(): string;

    protected getNoAuthentication() {
        return this.noAuthentication;
    }

    protected getParentPath(nest: boolean = true): string {
        if (this.parent) {
            if (this.parent.Id && nest) {
                return `${this.parent.getFullPath()}/${this.parent.Id}`;
            }
            return `${this.parent.getFullPath(nest)}`;
        }
        return '';
    }

    protected getFullPath(nest: boolean = true): string {
        if (!nest) {
            return `${this.getParentPath()}`;
        }
        return `${this.getParentPath(this.nested)}${this.getPath()}`;
    }

    public setAuth(token: string | (() => string)) {
        if (this.parent) {
            this.parent.setAuth(token);
        } else {
            this.token = token;
        }
    }

    protected getToken(): string {
        if (this.parent) {
            return this.parent.getToken();
        }
        if (typeof (this.token) === 'string') {
            return this.token;
        }
        if (typeof (this.token) === 'function') {
            return this.token();
        }
        return '';
    }

    protected dispatchEvent(event: string, data: any) {
        if (this.parent) {
            this.parent.dispatchEvent(event, data);
        } else {
            this.emit(event, data);
        }
    }
}

export abstract class AbstractResource<T> extends AbstractSubResource {
    /**
     * Return available methods
     */
    protected abstract getAllowedMethods(): Methods[];

    private checkIfAllowed(method: Methods) {
        if (this.getAllowedMethods().indexOf(method) < 0) {
            throw new Error(`${method} is not allowed`);
        }
    }

    private reqConf(contentType: string, params: any = {}) {
        const { token, ...resParams } = params;

        const headers: any = {
            'Content-Type': `${contentType}`,
        };

        if (this.getNoAuthentication()) {
            if (token) {
                headers.Authorization = `Bearer ${token}`;
            } else if (this.getToken() !== '') {
                headers.Authorization = `Bearer ${this.getToken()}`;
            }
        }

        return { headers, resParams };
    }

    public async getAll(searchQuery: any = {}, options: any = {}) {
        const { format } = options;
        this.checkIfAllowed(Methods.GET_ALL);
        const parsedFormatEnding = this.parseFormat(format);
        return axios.get(`${this.getFullPath()}${parsedFormatEnding}`, this.reqConf('application/json', { ...searchQuery, ...options }))
            .then((res) => res.data)
            .catch((err) => {
                if (err.response.status) {
                    this.dispatchEvent(err.response.status, err);
                }
                throw err;
            });
    }

    public async get(id = this.id, options: any = {}) {
        const { operationPath = '' } = options;
        if (!id) {
            throw new Error('Id argument or id in resource constructor is required');
        }
        this.checkIfAllowed(Methods.GET);
        return axios.get(`${this.getFullPath()}/${id}${operationPath}`, this.reqConf('application/json', options)).then((res) => res.data)
            .catch((err) => {
                if (err.response.status) {
                    this.dispatchEvent(err.response.status, err);
                }
                throw err;
            });
    }

    public async post(model, options: any = {}) {
        const { operationPath = '' } = options;
        this.checkIfAllowed(Methods.POST);

        return axios.post(
            `${this.getFullPath()}${this.getPostOperationPath(operationPath)}`,
            model,
            this.reqConf('application/json', options),
        ).then((res) => res.data)
            .catch((err) => {
                if (err.response.status) {
                    this.dispatchEvent(err.response.status, err);
                }
                throw err;
            });
    }

    public async patch(model, options: any = {}) {
        if (!this.id) {
            throw new Error('Id in resource constructor is required');
        }
        this.checkIfAllowed(Methods.PATCH);
        return axios.patch(
            `${this.getFullPath()}/${this.id}`,
            model,
            this.reqConf('application/merge-patch+json', options),
        ).then((res) => res.data)
            .catch((err) => {
                if (err.response.status) {
                    this.dispatchEvent(err.response.status, err);
                }
                throw err;
            });
    }

    public async create(model: any, options: any = {}): Promise<T | T[]> {
        return this.post(model, options);
    }

    public async update(model: any, options: any = {}): Promise<T> {
        return this.patch(model, options);
    }

    public async find(id, options: any = {}): Promise<T> {
        return this.get(id, options);
    }

    public async findAll(searchQuery: any = {}, options: any = {}): Promise<T[]> {
        return this.getAll(searchQuery, options);
    }

    public async delete(id = this.id, options: any = {}): Promise<T> {
        if (!id) {
            throw new Error('Id in resource constructor is required');
        }
        this.checkIfAllowed(Methods.DELETE);
        return axios.delete(
            `${this.getFullPath()}/${id}`,
            this.reqConf('application/merge-patch+json', options),
        ).then((res) => res.data)
            .catch((err) => {
                if (err.response.status) {
                    this.dispatchEvent(err.response.status, err);
                }
                throw err;
            });
    }

    private parseFormat(format: string) {
        switch (format) {
        case 'application/ld+json':
        case 'jsonld':
            return '.jsonld';
        default:
            return '';
        }
    }

    private getPostOperationPath(path: string) {
        if (path) {
            if (this.id) {
                return `/${this.id}${path}`;
            }
            return path;
        }
        return '';
    }
}
