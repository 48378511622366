
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tab extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ required: true }) private label!: string;

    @Prop({ required: false }) private icon!: string;
}
