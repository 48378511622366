/* eslint-disable import/no-extraneous-dependencies */
import { makeExecutableSchema } from 'graphql-tools';
import { graphql } from 'graphql';
// eslint-disable-next-line import/no-cycle
import { resolvers, typeDefs } from './resolvers';

export default class GraphQlMockServer {
    private schema = makeExecutableSchema({
        typeDefs,
        resolvers,
    });

    public execute: any = async (query: string, params: any = {}) => {
        const graphqlObj = graphql(this.schema, query, {}, {}, { ...params.variables });
        return graphqlObj.then((result) => {
            if (result.errors) {
                // TODO: handle errors
                result.errors.forEach((e) => console.error(e));
            }
            return result;
        });
    }
}
