// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { TermsAndConditionsType } from './types';

export default class GetHelpAndSupportHostessAssetsQuery
    extends AbstractQueryResource<GetHelpAndSupportHostessAssetsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($type: String!) {
            termsAndConditions(type: $type) {
                id,
                createdAt,
                content {
                    id,
                    category,
                    link,
                    fileName,
                    context {
                        metric,
                        value,
                    },
                    title,
                    originalFileName,
                    uploadDate,
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getHelpAndSupportHostessAssets';
    }
}

export type GetHelpAndSupportHostessAssetsResultType = {
    termsAndConditions: TermsAndConditionsType;
}

export type GetHelpAndSupportHostessAssetsParamsType = {
    type: string;
}
