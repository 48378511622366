import { ResolverProductType } from '../types';
import { customersSales } from './sales';
import { product } from './products';

const totalCustomerSales: (id: string) => string = (id) => {
    let res = 0;

    customersSales(id).forEach((item) => {
        item.products.forEach((productItem) => {
            const orderProduct: ResolverProductType = product(productItem.id);
            const totalProduct: number = Math.floor(orderProduct.price * 100)
             * productItem.quantity;
            res += totalProduct;
        });
    });

    return String(res / 100);
};

export { totalCustomerSales };
