
import { Vue, Component } from 'vue-property-decorator';
import componentsControls from '@/modules/ComponentsControls';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UISnackbars extends Vue {
    showSuccessSnackbar() {
        componentsControls.showSuccessMessage({ message: 'Success snackbar' });
    }

    showErrorSnackbar() {
        componentsControls.showErrorMessage({ message: 'Error snackbar' });
    }
}
