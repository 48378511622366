import { AbstractResource, Methods } from '../AbstractResource';

export default class SendEventInvite extends AbstractResource <string> {
    private eventToken: string = '';

    protected noAuthentication: boolean = true;

    protected getAllowedMethods(): Methods[] {
        return [Methods.POST];
    }

    protected getPath(): string {
        return `/event-invite/${this.eventToken}`;
    }

    public sendInvitation(eventToken, data): Promise<string> {
        this.eventToken = eventToken;

        return this.post(data);
    }
}
