import { ResolverEnrolleeType } from '../types';
import { enrollee } from './enrollee';
import { totalCustomerSales } from './totalSales';

const enrolleeCustomers: (enrolleeId: string) => ResolverEnrolleeType[] = (enrolleeId) => {
    const enrolleeItem: ResolverEnrolleeType = enrollee(enrolleeId);

    return enrolleeItem.customers.map((customer) => {
        const customerEnrollee = enrollee(customer);
        return {
            ...customerEnrollee,
            totalSales: totalCustomerSales(customer),
        };
    });
};

export { enrolleeCustomers };
