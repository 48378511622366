
import { Vue, Component } from 'vue-property-decorator';
import componentSandbox from '@/modules/ComponentsSandbox';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { FileAreaCategoryType, FileType } from '@/components/fileArea/types';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIFileArea extends Vue {
    @Get(componentSandbox) fileAreaCategories!: FileAreaCategoryType[];

    @Sync(componentSandbox) fileAreaFiles!: FileType[];

    onFileAdd(files: FileType[]) {
        componentSandbox.addFilesToFileArea(files);
    }

    onFileRemove(index: number) {
        componentSandbox.removeFileFromFileArea(index);
    }
}
