
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import helpAndSupportAssets from '@/modules/HelpAndSupport/assets';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import FileViewer from '@/projectComponents/fileViewer/index.vue';

@Component({
    components: {
        FileViewer,
    },
})
export default class HelpAndSupportAssetsPreview extends Vue {
    @Get(helpAndSupportAssets) private loading!: boolean;

    @Get(helpAndSupportAssets) private files!: AssetType[];

    @Get(helpAndSupportAssets) private previewFileId!: string;

    get file(): AssetType|null {
        const res = this.files.find((file) => file.id === this.previewFileId);
        return res || null;
    }
}
