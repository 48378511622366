
import Vue from 'vue';
import Component from 'vue-class-component';
import settings from '@/modules/Settings';
import installManager from '@/modules/Settings/services/installManager';
import { Get } from '@/utils/vuex-module-mutators';
import { BrowserDetector } from '@plumtreesystems/utils';

@Component
export default class AppInstall extends Vue {
    handleCancel() {
        installManager.onCancel();
    }

    get browserName() {
        return BrowserDetector.getName();
    }

    @Get(settings) installReady!: boolean;
}
