
import { Component, Prop, Vue } from 'vue-property-decorator';
import componentsControls from '@/modules/ComponentsControls';

@Component
export default class Tabs extends Vue {
    @Prop({ default: 'centered' }) private alignment!: string;

    @Prop({ default: '' }) private activeTab!: string;

    @Prop({ default: 150 }) private minSwipe!: number;

    @Prop({ required: true }) private ariaLabel!: string;

    tabChange(val: string) {
        this.$emit('tabChange', val);
    }

    lock(e) {
        componentsControls.setSwipeFrom(this.unify(e).clientX);
    }

    async move(e) {
        const direction = await componentsControls
            .handleSwipe({
                end: this.unify(e).clientX,
                swipeLength: this.minSwipe,
            });
        const wrapper = this.$refs.tabsWrapper as Vue;

        // @ts-ignore
        const tabs = wrapper.$slots.default.map((item) => item.componentInstance.id);

        const indexOfTab = tabs.indexOf(this.activeTab);
        const nextTab = tabs[indexOfTab + direction];

        if (nextTab) {
            this.$emit('tabChange', nextTab);
        }
    }

    unify(e) {
        return e.changedTouches ? e.changedTouches[0] : e;
    }

    mounted() {
        const wrapper = this.$refs.tabsWrapper as Vue;
        const el = wrapper.$el;

        el.addEventListener('touchstart', this.lock, false);

        el.addEventListener('touchend', this.move, false);

        this.setAriaAttributes();
    }

    beforeDestroy() {
        const wrapper = this.$refs.tabsWrapper as Vue;
        const el = wrapper.$el;

        el.removeEventListener('touchstart', this.lock);

        el.removeEventListener('touchend', this.move);
    }

    setAriaAttributes() {
        const tabsNavigation = this.$el.children[0];
        tabsNavigation.setAttribute('role', 'tablist');
        tabsNavigation.setAttribute('aria-label', this.ariaLabel);

        this.$nextTick(() => {
            for (let index = 0; index < tabsNavigation.children.length - 1; index++) {
                const child = tabsNavigation.children[index];
                child.setAttribute('role', 'tab');

                const childIcon = (child as HTMLElement).querySelector('i.md-icon');
                if (childIcon) {
                    childIcon.setAttribute('aria-hidden', 'true');
                }
            }
        });

        const tabPanel = this.$el.children[1];
        tabPanel.setAttribute('role', 'tabpanel');
    }
}
