
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { Vue, Component } from 'vue-property-decorator';
import { Sync, Get } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { EditorContentType } from '@/components/textField/types';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UITextEditors extends Vue {
    @Sync(componentsSandbox) textEditorContent!: string;

    @Sync(componentsSandbox) textEditorContentWithError!: string;

    @Sync(componentsSandbox) textEditorContentWithHelper!: string;

    @Sync(componentsSandbox) textEditorDisabledContent!: string;

    @Sync(componentsSandbox) textContent!: string;

    @Sync(componentsSandbox) textContentDelta!: EditorContentType;

    @Get(componentsSandbox) textEditorOptions!: object;

    get deltaToHtml() {
        const converter = new QuillDeltaToHtmlConverter(this.textContentDelta.ops);
        return converter.convert();
    }

    setDelta(val) {
        componentsSandbox.setTextContentDelta(val);
    }
}
