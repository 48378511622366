import AuthLocalStorageManager from '@/modules/Auth/services/authLocalStorageManager';
import { BrowserDetector } from '@plumtreesystems/utils';
import { AbstractResource, Methods } from '../AbstractResource';

export default class ErrorResource extends AbstractResource<string> {
    protected getAllowedMethods(): Methods[] {
        return [Methods.POST];
    }

    protected getPath(): string {
        return '/special/register_failure';
    }

    public registerFailure(error: Error): Promise<string> {
        return this.post(JSON.stringify({
            loggableError: error,
            token: AuthLocalStorageManager.getAuthToken(),
            browser: BrowserDetector.getName(),
        }));
    }
}
