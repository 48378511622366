import { enrollees } from './enrollee';
import { ResolverEnrolleeType } from '../types';
import getToken from '../services/tokenResolver';

const hostesses = () => enrollees.filter((enrollee) => enrollee.id !== getToken() && enrollee.id !== '-1');

const hostess: (id: string) => ResolverEnrolleeType = (id) => hostesses()
    .find((item) => item.id === id)!;

export { hostesses, hostess };
