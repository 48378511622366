
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PropertyInfoDisplay extends Vue {
    @Prop({ required: true }) private value!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: '' }) private label!: string;

    @Prop({ default: '' }) private secondary!: string;

    @Prop({ default: false }) private html!: boolean;

    @Prop({ default: '' }) private href!: string;

    get elementClasses() {
        return [
            'PropertyInfo__Container',
            { 'PropertyInfo--withLabel': this.label },
            { 'PropertyInfo--withSecondary': this.secondary },
        ];
    }

    checkIfDataAvailable(data: any): boolean {
        if (!data || data === '') {
            return false;
        }

        return true;
    }
}
