

import { Component, Vue, Prop } from 'vue-property-decorator';
import GoogleMapsApiLoader from 'google-maps-api-loader';
import env from '@/environment';

@Component
export default class GoogleMapLoader extends Vue {
    @Prop({ required: true }) private mapConfig!: object;

    private google: any | null = null;

    private map: any | null = null;

    private apiKey: string = env.VUE_APP_GOOGLE_API_KEY;

    async mounted() {
        const config: any = {
            libraries: ['places'],
            apiKey: this.apiKey,
        };

        const googleMapApi = await GoogleMapsApiLoader({
            ...config,
        });

        this.google = googleMapApi;
        this.initializeMap();
    }

    initializeMap() {
        const mapContainer = this.$refs.googleMap;

        const { google } = this;
        if (google !== null) {
            this.map = new google.maps.Map(mapContainer, this.mapConfig);
        }
    }
}
