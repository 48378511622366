
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { PaginationParamsType } from '@/components/pagination/types';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIPagination extends Vue {
    @Get(componentsSandbox, 'pageOffset') offset!: number;

    @Get(componentsSandbox, 'totalPageItems') total!: number;

    get limit(): number {
        return 20;
    }

    pageChange(params: PaginationParamsType) {
        const { offset } = params;

        componentsSandbox.setCurrentPageOffset(offset);
    }
}
