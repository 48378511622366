
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { FOCUSABLE_ELEMENTS_SELECTOR, KEY_TAB } from './constants';

@Component
export default class FocusLock extends Vue {
    @Prop({ required: true }) private active!: boolean;

    mounted() {
        if (this.active) {
            this.lockFocusInElement();
        }
    }

    @Watch('active')
    onActivate() {
        if (this.active) {
            this.lockFocusInElement();
        } else {
            this.$el.removeEventListener('keydown', () => this.handleKeyDown);
        }
    }

    lockFocusInElement() {
        const focusableElements = this.$el.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR);

        this.$el.addEventListener('keydown', (e) => this.handleKeyDown(e, focusableElements));
    }

    handleKeyDown(e, elements) {
        const firstFocusableElement = elements[0];
        const lastFocusableElement = elements[elements.length - 1];

        switch (e.keyCode) {
        case KEY_TAB:
            if (elements.length === 1) {
                e.preventDefault();
                break;
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    e.preventDefault();
                    lastFocusableElement.focus();
                }
            } else if (document.activeElement === lastFocusableElement) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
            break;
        default:
            break;
        }
    }
}
