
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { ErrorType } from '@plumtreesystems/utils';
import invite from '@/modules/Invite';
import QrcodeVue from 'qrcode.vue';

@Component({
    components: {
        QrcodeVue,
    },
})
export default class QrCodeInvite extends Vue {
    @Get(invite) private formErrors!: ErrorType

    @Prop() private value!: string;
}
