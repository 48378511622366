

import { Component, Vue } from 'vue-property-decorator';
import calendar from '@/modules/Calendar';
import { Get } from '@/utils/vuex-module-mutators';

@Component
export default class CalendarTimeControls extends Vue {
    @Get(calendar) isLoading!: boolean;

    changeScheduleDisplay(type: string) {
        calendar.changeSelectedDateTime(type);
    }
}
