const run = `type Run {
    id: String!
    from: String!
    to: String!
    label: String!
}`;

export {
    run,
};
