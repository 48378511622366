const eventStatistics = `type EventsStatistics {
    totalEvents: Int!,
    totalSales: Float!,
    avgSalesPerEvent: Float!,
    avgCustomersPerEvent: Float!,
    finishedEvents: Int!,
    bookedEvents: Int!,
    projectedSales: Float!
}`;

const eventsStatisticsQuery = `
eventsTotals(dateFrom: DateTime, dateTo: DateTime, type: String!): EventsStatistics
`;

export {
    eventStatistics, eventsStatisticsQuery,
};
