import env from '@/environment';

export default class GraphConfig {
    private token?: string | (() => string);

    private path: string = env.VUE_APP_SERVER_URL;

    public setToken(token: string | (() => string)) {
        this.token = token;
    }

    public getPath(): string {
        return this.path;
    }

    public getToken(): string {
        if (typeof (this.token) === 'string') {
            return this.token;
        }
        if (typeof (this.token) === 'function') {
            return this.token();
        }
        return '';
    }
}
