import env from '@/environment';
import LocalStorageManager from '@/utils/local-storage-manager';

export default (store) => {
    store.subscribe((m, state) => {
        if (env.VUE_APP_STORE_SERIALIZATION === 'true') {
            const localData = LocalStorageManager.getResolvedStoreState();

            const data = localData ? { ...localData as Object } : {};

            const moduleName = m.type.split('/')[0];

            if (!LocalStorageManager.moduleExcluded(moduleName)) {
                data[moduleName] = state[moduleName];
                LocalStorageManager.setStoreState(data);
            }
        }
    });
};
