import API from '@/api/graphQL';
import { GetOrdersParamsType } from '@/api/graphQL/graphNodes/GetOrdersQuery';

export default class OrdersRepository {
    static getOrders(params: GetOrdersParamsType) {
        return API
            .getOrders()
            .query(params);
    }
}
