
import Vue from 'vue';
import Component from 'vue-class-component';
import impersonation from '@/modules/Impersonation';

@Component
export default class EndImpersonation extends Vue {
    created() {
        impersonation.endImpersonation();
        this.$router.back();
    }
}
