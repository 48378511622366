
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';

@Component
export default class Clipboard extends Vue {
    @Prop({ default: '' }) private id!: string;

    @Prop({ required: true }) private url;

    private showMessage = false;

    handleSuccess() {
        this.showMessage = true;
        setTimeout(this.hideMessage, 2000);
    }

    handleError(e) {
        console.error(e);
    }

    hideMessage() {
        this.showMessage = false;
    }
}
