
import { Vue, Component, Prop } from 'vue-property-decorator';
import { FORM_ERROR_TOOLTIP } from '@/modules/constants';

@Component
export default class FormErrorTooltip extends Vue {
    @Prop({ default: false }) private displayTooltip!: boolean;

    @Prop({ required: true }) private id!: string;

    @Prop({ default: '' }) private error!: string;

    get errorMessage() {
        const { error, defaultMessage } = this;

        return error !== '' ? error : defaultMessage;
    }

    get defaultMessage() {
        return FORM_ERROR_TOOLTIP.errorMessage;
    }
}
