import { envObjectType } from '@/types';

// @ts-ignore
// eslint-disable-next-line import/no-mutable-exports
let windowProcessEnv: envObjectType = {};

// @ts-ignore
if (typeof global.process !== 'undefined') {
    // @ts-ignore
    windowProcessEnv = { ...process.env, ...global.process.env };
} else {
    // @ts-ignore
    windowProcessEnv = process.env;
}

export default windowProcessEnv;
