import { ResolverSalePaymentInfo } from '../types';

const defaultPayment: ResolverSalePaymentInfo = {
    currency: null,
    tenderType: null,
    amount: null,
    paymentMethod: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    county: null,
    postalCode: null,
    country: null,
    paymentDate: null,
};

const salePayment: () => ResolverSalePaymentInfo = () => {
    const paymentData: Partial<ResolverSalePaymentInfo> = {
        tenderType: 'SISTERPAY',
        paymentMethod: 'SISTERPAY',
    };

    return {
        ...defaultPayment,
        ...paymentData,
    };
};

export {
    salePayment,
};
