// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { InviteType } from './types';

export default class GetInviteUrlQuery extends AbstractQueryResource<GetInviteUrlResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                vanityUrl,
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getInviteUrl';
    }
}

export type GetInviteUrlResultType = {
    profile: InviteType;
}
