
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RadioOption extends Vue {
    @Prop({ default: '' }) private id!: string;

    @Prop({ default: '' }) private label!: string;

    @Prop({ default: '' }) private name!: string;

    @Prop({ default: '' }) private helperText!: string;

    @Prop({ required: true }) private value!: string | number | boolean;

    @Prop({ default: true }) private checkedValue!: string | number | boolean;

    @Prop({ default: false }) private error!: boolean;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: 'radio' }) private type!: string;

    get elementClasses() {
        return [
            'RadioButton__Container',
            { 'RadioButton__Container--checked': this.shouldBeChecked() },
            { 'RadioButton--error': this.error !== false },
            { 'RadioButton--disabled': this.disabled },
            { RadioButton__RadioType: this.type === 'radio' },
            { RadioButton__SwitchType: this.type === 'switch' },
        ];
    }

    get switchClasses() {
        return [
            'RadioButton__SwitchContainer',
            { 'RadioButton__Switch--checked': this.shouldBeChecked() },
        ];
    }

    get ariaRole() {
        return this.type ?? 'checkbox';
    }

    handleUpdate(): void {
        if (typeof this.value === 'boolean') {
            this.$emit('input', !this.value);
        } else {
            this.$emit('input', this.value);
        }
    }

    shouldBeChecked(): boolean {
        return this.value === this.checkedValue;
    }

    findCorrectIcon(): string {
        const checked: string = this.type === 'radio' ? 'radio_button_checked' : 'check_box';
        const unchecked: string = this.type === 'radio' ? 'radio_button_unchecked' : 'check_box_outline_blank';
        return this.shouldBeChecked() ? checked : unchecked;
    }
}
