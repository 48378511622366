
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';
import system from '@/modules/System';

@Component
export default class LinkInvite extends Vue {
    @Prop() private url!: string;

    @Prop() private title!: string;

    @Prop() private webShareTitle!: string;

    @Prop() private webShareText!: string;

    @Get(system) private screenType!: string;

    get webShareApiSupported(): boolean {
        const nav: any = navigator;

        if (nav.share && this.screenType !== 'desktop') {
            return true;
        }

        return false;
    }

    get pictureBaseUrl() {
        return `${env.BASE_URL}img/icons/social/`;
    }

    shareViaWebShare() {
        const nav: any = navigator;
        nav.share({
            title: this.webShareTitle,
            text: this.webShareText,
            url: this.url,
        });
    }
}
