
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RegistrationStepsOptionsType } from '@/projectComponents/registrationSteps/types';
import Step from './step/index.vue';

@Component({
    components: {
        Step,
    },
})
export default class RegistrationSteps extends Vue {
    @Prop() private steps!: RegistrationStepsOptionsType[];

    @Prop() private current!: string;
}
