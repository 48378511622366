import API from '@/api/graphQL';
import { GetSisterCreditParamsType } from '@/api/graphQL/graphNodes/GetSisterCreditQuery';

export default class SisterCreditRepository {
    static getSisterCredit(params: GetSisterCreditParamsType) {
        return API
            .getSisterCredit()
            .query(params);
    }
}
