/* eslint-disable prefer-object-spread */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import Vue from 'vue';
import { createDecorator } from 'vue-class-component';
import {
    Module, VuexModule, Mutation,
} from 'vuex-module-decorators';

export interface CollectionMap<T> {
    [key: string ]: T;
}

export function getId(element:any) {
    if (!element.hasOwnProperty('id')) {
        throw new Error('Element has no id, please define custom getId method or add id to the element');
    }
    return element.id;
}

/**
 * Takes the properties on object from parameter source and adds them to the object
 * parameter target
 * @param {object} target  Object to have properties copied onto from y
 * @param {object} source  Object with properties to be copied to x
 */
export function addPropertiesToObject(target: any, source: any) {
    for (const k of Object.keys(source || {})) {
        Object.defineProperty(target, k, {
            get: () => source[k],
        });
    }
}

export class CollectionVuexModule<T> extends VuexModule {
    static initData: any[] = [];

    public collection: CollectionMap<Partial<T>> = {};

    @Mutation
    public setElements(elements: Partial<T>[]) {
        const collection = {};
        for (let i = 0; i < elements.length; i++) {
            const id = getId(elements[i]);
            if (collection.hasOwnProperty(id)) {
                throw new Error('Duplicate id in collection!');
            }
            collection[id] = elements[i];
        }
        Vue.set(this, 'collection', collection);
    }

    @Mutation
    public addElement(element: Partial<T>) {
        const id = getId(element);
        if (this.collection.hasOwnProperty(id)) {
            throw new Error('Duplicate id in collection!');
        }
        Vue.set(this.collection, id, element);
    }

    @Mutation
    public removeElement(id: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: rm, ...rest } = this.collection;
        Vue.set(this, 'collection', rest);
    }

    public get elements(): Partial<T>[] {
        const state = this.collection;
        const elements = Object.getOwnPropertyNames(state).filter((key) => key !== '__ob__')
            .map((key) => state[key]) as any;
        return elements;
    }
}

function generateNameFromPath(path: string) {
    return `set${path.split('.').map((pathItem) => pathItem[0].toUpperCase() + pathItem.substr(1))
        .join('')}`;
}

function generateMutations(state: any, path: string = '') {
    let mutations:any = {};

    Object.keys(state).forEach((key: string) => {
        const propertyPath = path === '' ? key : `${path}.${key}`;
        const name = generateNameFromPath(propertyPath);
        const mutation = (mutationState: any, val: any) => {
            const mutationPath = propertyPath.split('.');
            if (mutationPath.length > 1) {
                const ref = mutationPath.slice(0, -1)
                    .reduce((prev, current) => prev[current], mutationState);
                ref[mutationPath[mutationPath.length - 1]] = val;
            } else {
                mutationState[propertyPath] = val;
            }
        };
        mutations[name] = mutation;
        if (state[key] && typeof state[key] === 'object' && !Array.isArray(state[key])) {
            mutations = { ...mutations, ...generateMutations(state[key], propertyPath) };
        }
    });

    return mutations;
}

function collectionAccessor(state: any, path: string, id: string | number) {
    const mutationPath = path.split('.');
    if (mutationPath.length > 1) {
        const ref = mutationPath.slice(0, -1)
            .reduce((prev, current) => prev[current], state.collection[id]);
        return ref;
    }
    return state.collection[id];
}

function generateCollectionMutations(state: any, path: string = '') {
    let mutations:any = {};

    Object.keys(state).forEach((key: string) => {
        const propertyPath = path === '' ? key : `${path}.${key}`;
        if (typeof state[key] !== 'object' && !Array.isArray(state[key])) {
            const name = generateNameFromPath(propertyPath);
            const mutation = (mutationState: any, payload: {id: string | number, val: any}) => {
                const ref = collectionAccessor(mutationState, propertyPath, payload.id);
                ref[key] = payload.val;
            };

            mutations[name] = mutation;
        } else {
            if (typeof state[key] === 'object' && !Array.isArray(state[key])) {
                mutations = {
                    ...mutations,
                    ...generateCollectionMutations(state[key], propertyPath),
                };
            }

            const name = generateNameFromPath(propertyPath);
            mutations[name] = (mutationState: any, payload: {id: string | number, val: any}) => {
                const ref = collectionAccessor(mutationState, propertyPath, payload.id);
                ref[key] = payload.val;
            };
        }
    });

    return mutations;
}

const reservedKeys = ['actions', 'getters', 'mutations', 'modules', 'state', 'namespaced', 'commit'];
function stateFactory(module: Function) {
    const state = new module.prototype.constructor({});
    const s = {};
    Object.keys(state).forEach((key: string) => {
        if (reservedKeys.indexOf(key) !== -1) {
            if (typeof state[key] !== 'undefined') {
                throw new Error(
                    `ERR_RESERVED_STATE_KEY_USED: You cannot use the following
                    ['actions', 'getters', 'mutations', 'modules', 'state', 'namespaced', 'commit', 'collection']
                    as fields in your module. These are reserved as they have special purpose in Vuex`,
                );
            }
            return;
        }
        if (state.hasOwnProperty(key)) {
            if (typeof state[key] !== 'function') {
                (s as any)[key] = state[key];
            }
        }
    });
    return s;
}

function getFromPath(state: any, path: string, id: string | number = '') {
    if (id) {
        return path.split('.').reduce((prev, current) => prev[current], state.collection[id]);
    }
    return path.split('.').reduce((prev, current) => prev[current], state);
}

export function AutoMutations(constructor: any | VuexModule) {
    if (!constructor.hasOwnProperty('mutations')) {
        constructor.mutations = Object.assign({}, constructor.mutations);
    }
    const factoryState = stateFactory(constructor);
    constructor.mutations = { ...generateMutations(factoryState), ...constructor.mutations };
    return constructor;
}

export function CollectionModule<T>(
    options: {
        dynamic?: boolean,
        store: any,
        name?: string,
        namespaced?: boolean,
        stateFactory?: boolean,
        item: any
    },
) {
    return (constructor: any | CollectionVuexModule<T>) => {
        if (!constructor.hasOwnProperty('mutations')) {
            constructor.mutations = Object.assign({}, constructor.mutations);
        }
        if (!constructor.hasOwnProperty('getters')) {
            constructor.getters = Object.assign({}, constructor.getters);
        }
        if (!constructor.hasOwnProperty('state')) {
            constructor.state = Object.assign({}, constructor.state);
        }
        const parent = Object.getPrototypeOf(constructor);
        const factoryState = stateFactory(options.item);
        constructor.mutations = {
            ...generateCollectionMutations(factoryState),
            ...constructor.mutations,
        };
        constructor.state = {
            ...constructor.state,
            collection: {},
        };
        // set collection getter from parent
        const elementsGetter = Object.getOwnPropertyDescriptor(parent.prototype, 'elements');
        if (!elementsGetter) throw new Error('Inheritance for collections are not supported');
        constructor.getters = {
            ...constructor.getters,
            elements(state, getters, rootState, rootGetters) {
                const thisObj = {
                    context: {
                        state, getters, rootState, rootGetters,
                    },
                };
                addPropertiesToObject(thisObj, state);
                addPropertiesToObject(thisObj, getters);
                if (!elementsGetter.get) throw new Error('Collection property can not be overridden');
                const got = elementsGetter.get.call(thisObj);
                return got;
            },
        };
        const decoratedConstructor = Module({ ...options, dynamic: undefined })(constructor);
        if (options.dynamic) {
            if (!options.name) {
                throw new Error('Name of module not provided in decorator options');
            }
            // load initial data
            decoratedConstructor.state.collection = decoratedConstructor.initData.reduce(
                (s, element) => ({ ...s, [getId(element)]: element }),
                {},
            );
            options.store.registerModule(options.name, decoratedConstructor);
        }
        return decoratedConstructor;
    };
}

function getCollectionId(options, component) {
    const idPropName = 'id';

    if (!options.methods) {
        options.methods = {};
    }

    if (options.methods.hasOwnProperty('_collectionId')) {
        // eslint-disable-next-line no-underscore-dangle
        return options.methods._collectionId(component);
    }
    if (!options.props || !options.props[idPropName]) {
        throw Error('Id property not found or element does not exist');
    } else {
        return component[idPropName];
    }
}

export function CId(idGetter: (any) => string) {
    return createDecorator((options) => {
        if (!options.methods) {
            options.methods = {};
        }
        // eslint-disable-next-line no-underscore-dangle
        options.methods._collectionId = idGetter;
    });
}

export function Get(module: { [key: string]: Function | any; }, prop?: string) {
    return createDecorator((options, key) => {
        if (!options.computed) options.computed = {};
        const propName = prop || key;
        options.computed[key] = {
            get: () => getFromPath(module, propName),
        };
    });
}

export function Sync(module: { [key: string]: Function | any; }, prop?: string) {
    return createDecorator((options, key) => {
        if (!options.computed) options.computed = {};
        const propName = prop || key;
        options.computed[key] = {
            get: () => getFromPath(module, propName),
            set: module[generateNameFromPath(propName)],
        };
    });
}

export function CGet(module: { [key: string]: Function | any; }, prop?: string) {
    return createDecorator((options, key) => {
        if (!options.computed) options.computed = {};
        const propName = prop || key;
        options.computed[key] = {
            get() { return getFromPath(module, propName, getCollectionId(options, this)); },
        };
    });
}

export function CSync(module: { [key: string]: Function | any; }, prop?: string) {
    return createDecorator((options, key) => {
        if (!options.computed) options.computed = {};
        const propName = prop || key;
        options.computed[key] = {
            get() {
                getCollectionId(options, this);
                return getFromPath(module, propName, getCollectionId(options, this));
            },
            set(val) {
                return module[generateNameFromPath(propName)]({
                    val,
                    id: getCollectionId(options, this),
                });
            },
        };
    });
}
