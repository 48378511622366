import env from '@/environment';
import auth from '@/modules/Auth';

const isRouteAllowedForRole: (
    roles,
    allowedPermissions
) => boolean = (roles, allowedPermissions) => roles.reduce((agr, role) => {
    if (agr) {
        return agr;
    }

    if (allowedPermissions.includes(role)) {
        return true;
    }

    return false;
}, false);

const protectedRoute = (
    allowedPermissions: Array<string> = [],
    bannedPermissions: Array<string> = [],
) => (to, from, next) => {
    let roles: string[] = [];

    if (env.VUE_APP_MOCK_AUTHENTICATOR === 'true') {
        roles = (['ROLE_USER', 'ROLE_ADMIN', ...auth.myAccount.roles])
            .filter((value, index, self) => self.indexOf(value) === index);
    } else {
        roles = [...auth.myAccount.roles];
    }

    if (roles) {
        const hasPermission = allowedPermissions.length > 0
            ? isRouteAllowedForRole(roles, allowedPermissions)
            : true;

        const isBanned = roles.reduce((agr, role) => {
            if (agr) {
                return agr;
            }

            if (bannedPermissions.includes(role)) {
                return true;
            }

            return false;
        }, false);

        if (hasPermission && !isBanned) {
            next();
        } else {
            next(false);
        }
    }
};

export default protectedRoute;
