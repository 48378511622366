import API from '@/api/graphQL';
import { GetCustomerNoParamsType } from '@/api/graphQL/graphNodes/GetCustomerNoQuery';
import { GetHostessRegistrationParamsType } from '@/api/graphQL/graphNodes/GetHostessRegistrationQuery';
import { RegisterAmbassadorDriverLicenseParamsType } from '@/api/graphQL/graphNodes/RegisterAmbassadorDriverLicenseQuery';
import { RegisterAmbassadorPassportParamsType } from '@/api/graphQL/graphNodes/RegisterAmbassadorPassportQuery';
import { RegisterAmbassadorParamsType } from '@/api/graphQL/graphNodes/RegisterAmbassadorQuery';
import { RegisterHostessParamsType } from '@/api/graphQL/graphNodes/RegisterHostessQuery';

export default class RegisterRepository {
    static registerAmbassador(data: RegisterAmbassadorParamsType, token: string) {
        return API
            .registerAmbassador()
            .query(data, { token });
    }

    static checkCustomerNumber(params: GetCustomerNoParamsType, token: string|null) {
        return API
            .getCustomerNumber()
            .query(params, { token });
    }

    static uploadProfileImage(data, token) {
        return API
            .uploadProfilePicture()
            .query(data, { formData: true, token });
    }

    static passportSubmit(data: RegisterAmbassadorPassportParamsType, token: string) {
        return API
            .registerPassport()
            .query(data, { token });
    }

    static driverLicenseSubmit(data: RegisterAmbassadorDriverLicenseParamsType, token: string) {
        return API
            .registerDriverLicense()
            .query(data, { token });
    }

    static getHostessRegistration(params: GetHostessRegistrationParamsType, token: string) {
        return API
            .getHostessRegistration()
            .query(params, { token });
    }

    static registerHostess(data: RegisterHostessParamsType, token: string) {
        return API
            .registerHostess()
            .query(data, { token });
    }

    static getCustomerRegistration(type: string, token: string) {
        return API
            .getCustomerRegistration()
            .query({ type }, { token });
    }

    static registerCustomer(data: RegisterHostessParamsType, token: string) {
        return API
            .registerCustomer()
            .query(data, { token });
    }
}
